import { paths } from '@mosey/api-types';
import { useMatch, useParams, useRouteLoaderData } from 'react-router-dom';

export const useMailroomData = () => {
  const isGlobal = useMatch('/mail/:mailId?');

  return useRouteLoaderData(`mail/${isGlobal ? 'global' : 'location'}`) as {
    messages: paths['/api/legal_entity/mail']['get']['responses']['200']['content']['application/json'];
    users: paths['/api/users']['get']['responses']['200']['content']['application/json'];
    requirements?: paths['/api/requirements']['post']['responses']['200']['content']['application/json'];
    legalEntityRegion?: paths['/api/legal_entity/locations/{region_code}']['get']['responses']['200']['content']['application/json'];
  };
};

export const useCurrentMail = () => {
  const { mailId } = useParams();
  const { messages } = useMailroomData();

  return messages.find((m) => m.id === mailId);
};
