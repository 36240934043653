import clsx from 'clsx';
import { HTMLProps } from 'react';

type TableCellProps = Omit<HTMLProps<HTMLTableCellElement>, 'className'> & {
  verticalPadding?: 'none' | 'default';
};

export const TableCell = ({
  verticalPadding = 'default',
  children,
  ...rest
}: TableCellProps) => {
  return (
    <td
      className={clsx('pr-6 first:pl-6', {
        'py-4': verticalPadding === 'default',
        'align-middle': verticalPadding === 'none',
      })}
      {...rest}
    >
      {children}
    </td>
  );
};
