import { FunctionComponent } from 'react';
import { components } from '@mosey/api-types';
import { ManagedRequirementNote } from './ManagedRequirementNote';
import { formatEta, formatEtaAsProse } from '../../utils/format';

type RequirementEtaProps = {
  eta: components['schemas']['ETARange'];
};

export const RequirementEta: FunctionComponent<RequirementEtaProps> = ({
  eta,
}) => {
  const { value, unit } = formatEta(eta);
  return (
    <ManagedRequirementNote
      callout={
        <div className="flex flex-col items-center">
          <p className="mt-1 text-xs font-medium uppercase text-zinc-600">
            Processing time
          </p>
          <p className="pt-2 text-4xl font-bold leading-8 text-zinc-800">
            {value}
          </p>
          <p className="text-lg font-bold leading-6 text-zinc-800">{unit}</p>
        </div>
      }
      details={`${formatEtaAsProse(eta)} Processing times may vary.`}
    />
  );
};
