import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/solid';
import {
  ActionFunctionArgs,
  Form,
  Link,
  redirect,
  useNavigation,
  useRouteLoaderData,
} from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';
import { Pill } from '@mosey/components/badges/Pill';
import { UpdatedIcon } from '@mosey/components/Icons';
import { TruncatedList } from '@mosey/components/layout/TruncatedList';
import { Handbook, HandbookDataLoader, PublishableChange } from './types';
import { api } from '../../utils/fetchApi';
import { HandbookContainer } from './HandbookContainer';
import { getHandbookChanges } from './utils';

function getPhrasing(
  draft: Handbook,
  published: Handbook | undefined,
  changes: PublishableChange[],
) {
  let title = 'Your handbook is ready to be published!';
  let description =
    'Employees will access it by verifying their company email address';

  if (!draft.is_publishable) {
    if (published) {
      if (changes.length === 0) {
        title = 'There are no changes to publish';
        description = 'Your handbook is already up-to-date';
      } else {
        title = "You haven't adopted/updated all policies";
        description =
          'You will be able to publish your handbook once you adopt/update all policies';
      }
    } else {
      title = "You haven't adopted all policies";
      description =
        'You will be able to publish your handbook once you adopt all policies';
    }
  }

  return { title, description };
}

const Row = ({ change }: { change: PublishableChange }) => {
  return (
    <li
      className="flex items-center justify-between gap-2 border border-b-0 border-gray-100 px-3 py-2 first:rounded-t-lg last:rounded-b-lg last:border-b"
      aria-label={`${change.instance.title} was ${change.type === 'adopted' ? 'adopted' : 'updated'}`}
    >
      <p className="text-sm font-medium text-zinc-800">
        {change.instance.title}
      </p>
      <Pill
        Icon={change.type === 'adopted' ? CheckCircleIcon : UpdatedIcon}
        variant="success"
        size="small"
      >
        {change.type === 'adopted' ? 'Adopted' : 'Updated'}
      </Pill>
    </li>
  );
};

export const Publish = () => {
  const { draft, published } = useRouteLoaderData(
    'handbook',
  ) as HandbookDataLoader;
  const { state } = useNavigation();
  const changes = getHandbookChanges(draft!, published);
  const { description, title } = getPhrasing(draft!, published, changes);

  return (
    <HandbookContainer>
      <div className="flex grow flex-col items-start justify-center gap-y-6">
        {draft?.is_publishable ? (
          <CheckCircleIcon className="size-12 self-center text-teal-700 " />
        ) : (
          <InformationCircleIcon className="size-12 self-center text-zinc-700" />
        )}

        <div className="w-full space-y-2 text-center">
          <h2 className="text-2xl font-bold">{title}</h2>

          <p className="text-base">{description}</p>
        </div>
        <ul className="flex gap-10 self-center">
          {draft?.is_publishable ? (
            <>
              <li>
                <Button as={Link} variant="secondary" to="/handbook/overview">
                  Cancel
                </Button>
              </li>
              <li className="-ml-6">
                <Form action="/handbook/publish" method="POST">
                  <Button
                    disabled={!draft || !draft.is_publishable}
                    isLoading={state !== 'idle'}
                    name="intent"
                    value="handbook-publish"
                  >
                    Publish
                  </Button>
                </Form>
              </li>
            </>
          ) : (
            <li>
              <Button as={Link} to="/handbook/overview">
                View policies
              </Button>
            </li>
          )}
        </ul>
      </div>

      <TruncatedList title="Summary" isStacked>
        {changes.map((change) => (
          <Row key={change.instance.id} change={change} />
        ))}
      </TruncatedList>
    </HandbookContainer>
  );
};

Publish.action = async ({ request }: ActionFunctionArgs) => {
  const intent = (await request.formData()).get('intent');

  if (intent === 'handbook-publish') {
    try {
      await api({
        method: 'POST',
        url: '/api/handbook/publish',
      });

      return redirect('/handbook/overview');
    } catch (e: Response | unknown) {
      let error = 'We were unable to publish your handbook. Please try again.';

      if (e instanceof Response) {
        error = (await e.json()).detail;
      }

      return { ok: false, error, intent };
    }
  }

  return { ok: false, error: 'Unknown', intent };
};
