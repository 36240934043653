import { FunctionComponent, useEffect, useRef } from 'react';
import { RadarIcon } from '@mosey/components/Icons';
import { getFormattedDate } from '@mosey/utils/dates';
import { Pill, PillProps } from '@mosey/components/badges/Pill';
import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { PageTitle } from '@mosey/components/page-header/PageTitle';
import {
  CategoryTag,
  Section,
  TextTruncateOne,
  BatchApiStatusHandler,
} from '../components';
import {
  CategoryToIconMapping,
  CategoryToStringMapping,
} from '../utils/category';
import {
  Region,
  RequirementCategory,
  UpcomingLegislationStageEnum,
  UpcomingLegislation as UpcomingLegislationType,
} from '../types';
import { useBatchApi } from '../hooks';

const statusColorMapping: {
  [key in UpcomingLegislationStageEnum]: PillProps['variant'];
} = {
  [UpcomingLegislationStageEnum.Detected]: 'primary',
  [UpcomingLegislationStageEnum.Updated]: 'secondary',
  [UpcomingLegislationStageEnum.Finalized]: 'success',
};

const UpcomingLegislationEmpty: FunctionComponent = () => (
  <div className="flex flex-col justify-center border bg-white p-6">
    <div className="mb-6 size-10 rounded bg-gray-100 p-2 text-gray-500">
      <RadarIcon />
    </div>
    <div>
      <h3 className="text-xl font-bold">No upcoming legislation</h3>
      <p className="mt-2 text-gray-500">
        We&apos;ll notify you when there is upcoming legislation.
      </p>
    </div>
  </div>
);

type UpcomingLegislationCardProps = {
  id: string;
  title: string;
  categoryTag: RequirementCategory;
  location: Region;
  stage: UpcomingLegislationStageEnum;
  summary: string;
  effectiveDate?: string | null;
};

const UpcomingLegislationCard: FunctionComponent<
  UpcomingLegislationCardProps
> = ({ id, title, categoryTag, location, stage, effectiveDate, summary }) => {
  const effectiveDateText = effectiveDate
    ? getFormattedDate(new Date(effectiveDate))
    : 'Unavailable';

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && window.location.hash === `#${id}`) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [id]);

  return (
    <article
      ref={ref}
      className="border bg-white"
      style={{ scrollMarginTop: '6rem' }}
    >
      <div className="flex flex-wrap justify-between gap-11 p-7 md:flex-nowrap">
        <div className="flex flex-col justify-between">
          <h3 id={id} className="text-base font-bold text-gray-900">
            {title}
          </h3>
          <div className="mt-2.5 flex flex-wrap gap-y-2">
            <CategoryTag
              key={categoryTag}
              icon={CategoryToIconMapping[categoryTag]}
              text={CategoryToStringMapping[categoryTag]}
            />
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th className="px-6 text-left text-xs font-medium uppercase tracking-widest text-zinc-600">
                Location
              </th>
              <th className="px-6 text-left text-xs font-medium uppercase tracking-widest text-zinc-600">
                Stage
              </th>
              <th className="px-6 text-left text-xs font-medium uppercase tracking-widest text-zinc-600">
                Effective Date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="w-44 px-6 align-bottom font-medium leading-none text-zinc-700">
                {location.name}
              </td>
              <td className="w-44 px-6 align-bottom font-medium leading-none text-zinc-700">
                <Pill variant={statusColorMapping[stage]} size="small">
                  {stage}
                </Pill>
              </td>
              <td className="w-44 px-6 align-bottom font-medium leading-none text-zinc-700">
                {effectiveDateText}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex flex-col items-start border-t bg-sage-50 px-7 py-4 pb-6">
        <h3 className="mb-2 text-xs font-medium uppercase tracking-widest text-zinc-600">
          Summary
        </h3>
        <div className="flex w-full">
          <TextTruncateOne
            className="font-medium text-zinc-700"
            text={summary}
          />
        </div>
      </div>
    </article>
  );
};

type UpcomingLegislationProps = {
  upcomingLegislation: UpcomingLegislationType[];
};

export const UpcomingLegislation: FunctionComponent<
  UpcomingLegislationProps
> = ({ upcomingLegislation }) => {
  const allLocations = upcomingLegislation.map(
    (legislation) => legislation.region.id,
  );

  const locationsCount = new Set(allLocations).size;
  const locationsLabel = locationsCount > 1 ? 'Locations' : 'Location';

  const eventsCount = upcomingLegislation.length;
  const eventsLabel = eventsCount > 1 ? 'Updates' : 'Update';

  return (
    <div className="flex h-full flex-col">
      <PageHeader>
        <PageTitle>Upcoming Legislation</PageTitle>
      </PageHeader>

      <Section className="grow">
        <div className="flex items-center justify-between">
          <div className="mx-auto w-full max-w-5xl">
            <div className="space-y-5 pt-7">
              {upcomingLegislation.length ? (
                <>
                  <div className="ml-2 text-lg text-zinc-800">
                    <span className="font-bold">{`${locationsCount} ${locationsLabel}`}</span>
                    <span className="font-medium">{` - ${eventsCount} ${eventsLabel}`}</span>
                  </div>

                  {upcomingLegislation.map((legislation) => (
                    <UpcomingLegislationCard
                      key={legislation.id}
                      id={legislation.id}
                      title={legislation.title}
                      categoryTag={legislation.category}
                      location={legislation.region}
                      stage={legislation.stage}
                      effectiveDate={
                        legislation.effective_date
                          ? legislation.effective_date
                          : null
                      }
                      summary={legislation.description}
                    />
                  ))}
                </>
              ) : (
                <UpcomingLegislationEmpty />
              )}
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};

export const UpcomingLegislationView: FunctionComponent = () => {
  const batchResponse = useBatchApi([
    {
      url: '/api/legislation',
      method: 'GET',
    },
  ]);

  const componentPropsFn = ([
    upcomingLegislationResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ]: any[]): UpcomingLegislationProps => {
    return {
      upcomingLegislation: upcomingLegislationResponse,
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      componentProps={componentPropsFn}
      component={UpcomingLegislation}
    />
  );
};
