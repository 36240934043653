import { useState } from 'react';
import { Link } from 'react-router-dom';
import { TruncatedList } from '@mosey/components/layout/TruncatedList';
import { CardWrapper } from '@mosey/components/layout/CardWrapper';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { useTasksOverviewData } from '../../utils/hooks';
import {
  useIsHandbookEnabled,
  useIsHandbookPresent,
} from '../../../handbook/hooks/useIsHandbook';
import { Button } from '@mosey/components/buttons/Button';
import { XIcon } from '@heroicons/react/outline';

import handbookIcon from '../../../../assets/handbook.svg';

export const HandbookTodoCollection = () => {
  const { handbookTodos } = useTasksOverviewData();
  const isHandbookEnabled = useIsHandbookEnabled();
  const isHandbookPresent = useIsHandbookPresent();
  const [showHandbookCta, setShowHandbookCta] = useState<boolean>(
    handbookTodos.length > 0 &&
      (!isHandbookEnabled || !isHandbookPresent) &&
      localStorage.getItem('isHandbookCtaDismissed') !== 'true',
  );
  const handbookCta = (showHandbookCta && (
    <div className="relative flex rounded-md border border-sage-500 bg-sage-100 p-4">
      <button
        className="absolute right-3 top-3 border-none p-0"
        onClick={() => {
          localStorage.setItem('isHandbookCtaDismissed', 'true');
          setShowHandbookCta(false);
        }}
      >
        <XIcon className="size-4 text-sage-700" aria-label="Dismiss" />
      </button>
      <div>
        <img src={handbookIcon} alt="" className="w-32" />
      </div>
      <div className="w-full pl-4">
        <div className="text-sm font-semibold text-zinc-700">
          Launch your multi-state employee handbook in minutes.
        </div>
        <div className="pt-1 text-xs text-zinc-600">
          Everything you need to make a compliant handbook for all 50 states.
        </div>
        <div className="pt-2">
          <Button as={Link} to="/handbook" size="small" variant="secondary">
            Get started
          </Button>
        </div>
      </div>
    </div>
  )) || <></>;

  return (
    <TruncatedList
      title="Adopt Policies"
      body="Add state-specific policies to your employee handbook."
      extra={handbookCta}
      itemLabel="policies"
      as="nav"
      aria-live="polite"
      isStacked={true}
    >
      {handbookTodos.map(({ id, title, status, source }) => {
        let to = '';
        switch (source.type) {
          case 'requirement': {
            to = `/tasks/${id}`;
            break;
          }
          case 'question': {
            to = `/resolver/question/tasks/${id}`;
            break;
          }
        }

        return (
          <CardWrapper key={id} as="li">
            <TaskSummaryCard
              title={title}
              status={status === 'deferred' ? 'todo' : status}
              variant="nav"
              size="medium"
              to={to}
            />
          </CardWrapper>
        );
      })}
    </TruncatedList>
  );
};
