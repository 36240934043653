import { Suspense } from 'react';
import {
  ActionFunction,
  Await,
  defer,
  LoaderFunction,
  matchPath,
  redirect,
  useNavigation,
  useSearchParams,
} from 'react-router-dom';
import { isUSStateCode } from '@mosey/utils/constants/us-states';
import { toUTCMidnight } from '@mosey/utils/dates';
import { TaskStatus } from '@mosey/api-types';
import { api, apiBatch } from '../../../utils/fetchApi';
import { Loading } from '../../Loading';
import { TasksOverviewMainTasks } from './TasksOverviewMainTasks';
import { TasksOverviewSetupGate } from './TasksOverviewSetupGate';
import { TasksOverviewSidebar } from './sidebar/TasksOverviewSidebar';
import { TaskOverviewRegionFilters } from './region-filters/TaskOverviewRegionFilters';
import {
  useIsLocationDetail,
  useNextTasksOverviewLocationId,
  useTasksOverviewPendingData,
} from '../utils/hooks';
import { isoDate } from '../../../utils/format';
import { TasksOverviewActionResponse } from './types';
import { isLocationDetailPreview, setLocationDetailPreview } from '../utils';
import { ConfigurationBanner } from '../common/ConfigurationBanner';
import { IFetchApi } from '../../../utils/types';

export const action: ActionFunction = async ({
  request,
}): Promise<TasksOverviewActionResponse | Response> => {
  const { searchParams } = new URL(request.url);
  const locationId = searchParams.get('location_id');
  const { intent, ...data } = Object.fromEntries(await request.formData());

  if (intent === 'mark-as-done') {
    try {
      const batchSize = 25;
      const taskPublicIds = Object.values(data);
      for (let i = 0; i < taskPublicIds.length; i += batchSize) {
        const batch = taskPublicIds.slice(i, i + batchSize);
        await api({
          url: `/api/compliance/tasks/batch_status`,
          method: request.method,
          body: {
            // eslint-disable-next-line camelcase
            task_public_ids: batch,
            status: TaskStatus.done,
            // eslint-disable-next-line camelcase
            scoped_to_single_location: !!locationId,
          },
        });
      }
    } catch (error) {
      return {
        errors: {
          submit: 'Something went wrong, please try again.',
        },
        resolved: false,
      };
    }
  }

  return {
    errors: {},
    resolved: true,
  };
};

export const loader: LoaderFunction = async ({ params, request }) => {
  const { searchParams, pathname } = new URL(request.url);

  // TODO: Remove this once Tasks Overview is live on Location Detail
  if (searchParams.get('tasks-overview') === 'true') {
    setLocationDetailPreview();
  }

  const isLocationDetail = matchPath(
    '/locations/:countryId/:locationId/*',
    pathname,
  );

  // TODO: Remove this once Tasks Overview is live on Location Detail
  if (isLocationDetail && !isLocationDetailPreview()) {
    return redirect(`/locations/${params.countryId}/${params.locationId}`);
  }

  const locationId = searchParams.get('location_id') || params.locationId;
  const tasksSearchParams = new URLSearchParams();
  const inProgressSearchParams = new URLSearchParams();
  const tasksUrl = '/api/compliance/tasks';
  const endDate = toUTCMidnight(new Date());
  endDate.setDate(endDate.getDate() + 90);

  tasksSearchParams.set('end_date', isoDate(endDate));

  inProgressSearchParams.append('statuses', TaskStatus.in_progress);
  inProgressSearchParams.set('task_type', 'requirement');
  inProgressSearchParams.set('is_automation', 'true');

  if (locationId) {
    if (!isUSStateCode(locationId.toUpperCase())) {
      return redirect(`/home`);
    }

    tasksSearchParams.set('region_id', locationId);
    inProgressSearchParams.set('region_id', locationId);
  }

  const status = searchParams.get('status');

  switch (status) {
    case 'automated':
    case 'done':
      tasksSearchParams.append('statuses', TaskStatus.done);
      break;

    case 'managed':
      tasksSearchParams.delete('statuses');
      break;

    case 'todo':
    case 'overdue':
    default:
      tasksSearchParams.append('statuses', TaskStatus.todo);
      tasksSearchParams.append('excluded_tags', 'handbook');
      break;
  }

  tasksSearchParams.set('task_type', 'requirement');

  const mainTasksUrl = `${tasksUrl}?${tasksSearchParams.toString()}`;
  const inProgressTasksUrl = `${tasksUrl}?${inProgressSearchParams.toString()}`;
  const handbookTodosSearchParams = new URLSearchParams(tasksSearchParams);
  handbookTodosSearchParams.set('tags', 'handbook');
  handbookTodosSearchParams.set('statuses', TaskStatus.todo);
  handbookTodosSearchParams.delete('excluded_tags');
  const handbookTodosUrl = `${tasksUrl}?${handbookTodosSearchParams.toString()}`;

  const calls: Record<string, IFetchApi> = {
    tasks: {
      url: mainTasksUrl,
      method: 'GET',
    },
    handbookTodos: {
      url: handbookTodosUrl,
      method: 'GET',
    },
    legislation: {
      url: '/api/legislation',
      method: 'GET',
    },
    summary: {
      url: `/api/legal_entity/locations/summary?end_date=${isoDate(endDate)}`,
      method: 'GET',
    },
    inProgress: {
      url: inProgressTasksUrl,
      method: 'GET',
    },
  };

  if (isLocationDetail) {
    calls.configuration = {
      url: `/api/compliance/tasks/${locationId}/configuration`,
      method: 'GET',
    };
  } else {
    calls.connections = {
      url: `/api/connections`,
      method: 'GET',
    };
  }

  return defer({
    data: apiBatch(calls).then((response) => response.json()),
  });
};

export const Component = () => {
  const isLocationDetail = useIsLocationDetail();
  const [searchParams] = useSearchParams();
  const locationId = searchParams.get('location_id');
  const nextLocationId = useNextTasksOverviewLocationId();
  const navigation = useNavigation();
  const data = useTasksOverviewPendingData();

  return (
    <div className="relative flex grow flex-col overflow-auto @container/tasks-overview">
      <Suspense fallback={<Loading />}>
        <Await resolve={data}>
          {!isLocationDetail && <TaskOverviewRegionFilters />}

          {navigation.state === 'loading' &&
          locationId !== nextLocationId &&
          navigation.location.pathname === location.pathname ? (
            <Loading />
          ) : (
            <TasksOverviewSetupGate>
              <ConfigurationBanner />

              <div className="px-8 pb-6 @5xl/tasks-overview:grid @5xl/tasks-overview:grid-cols-[minmax(0,_1fr)_374px] @5xl/tasks-overview:gap-x-10 @5xl/tasks-overview:space-y-0">
                <TasksOverviewMainTasks />
                <TasksOverviewSidebar />
              </div>
            </TasksOverviewSetupGate>
          )}
        </Await>
      </Suspense>
    </div>
  );
};
