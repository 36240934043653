import { ActionFunctionArgs, Link, useRouteLoaderData } from 'react-router-dom';
import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { PageTitle } from '@mosey/components/page-header/PageTitle';
import { CopyField } from '@mosey/components/buttons/CopyField';
import { Button } from '@mosey/components/buttons/Button';
import { api } from '../../utils/fetchApi';
import { OverviewPolicySection } from './section/OverviewPolicySection';
import { Handbook, HandbookDataLoader } from './types';
import { HANDBOOK_URL } from '../../settings/config';
import { OverviewSignaturesSection } from './overview/OverviewSignaturesSection';
import { usePolicyList } from './hooks/usePolicyList';
import { Placeholder } from '@mosey/components/navigation/Placeholder';
import { usePolicySections } from './hooks/usePolicySections';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import { useHandbookPreviewLink } from './hooks/useHandbookPreviewLink';

function getDescription(draft?: Handbook, published?: Handbook) {
  if (published) {
    return 'Your handbook is live. Employees can view policies tailored to their location.';
  } else if (draft?.is_publishable) {
    return 'Now that you have adopted policies, your handbook is ready to be published.';
  } else {
    return 'Once you adopt policies, you will be able to publish your handbook. Employees can access the published handbook by authenticating with their work email.';
  }
}

export const Overview = () => {
  const { draft, published } = useRouteLoaderData(
    'handbook',
  ) as HandbookDataLoader;
  const { sections, hasActionableItems, actionableItems } = usePolicyList();
  const { unusedSections } = usePolicySections();
  const previewLink = useHandbookPreviewLink();

  return (
    <>
      <PageHeader>
        <PageTitle>Handbook</PageTitle>
      </PageHeader>
      <div className="flex flex-col items-start p-8">
        <div className="space-y-4">
          <p className="max-w-2xl text-zinc-800">
            {getDescription(draft, published)}
          </p>
          {published && (
            <div className="mt-5 flex flex-col gap-1 text-zinc-700">
              <p className="font-semibold text-zinc-800">Handbook Link</p>
              <div className="flex gap-4">
                <CopyField label="handbook link" id="handbook-copy">
                  {HANDBOOK_URL}
                </CopyField>
                {!draft?.is_publishable && (
                  <Button
                    as={Link}
                    variant="secondary"
                    to={previewLink}
                    rightIcon={<ExternalLinkIcon className="size-4" />}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Preview
                  </Button>
                )}
              </div>
            </div>
          )}
          {draft?.is_publishable && (
            <div className="flex gap-4">
              <Button
                as={Link}
                variant="secondary"
                to={previewLink}
                rightIcon={<ExternalLinkIcon className="size-4" />}
                target="_blank"
                rel="noreferrer noopener"
              >
                Preview
              </Button>
              <Button as={Link} to="/handbook/publish">
                Publish
              </Button>
            </div>
          )}
          {hasActionableItems && (
            <Button
              as={Link}
              to={`/handbook/section/${actionableItems[0].sectionId}/policies#${actionableItems[0].slug}`}
            >
              Configure and adopt policies
            </Button>
          )}
        </div>
        <div className="mt-8 flex w-full flex-col gap-8 lg:flex-row-reverse lg:justify-end">
          {published && <OverviewSignaturesSection />}
          <div className="flex w-full max-w-3xl flex-col items-stretch gap-6 lg:flex-1">
            {sections.map((section) => (
              <OverviewPolicySection
                key={section.section.id}
                section={section.section}
                items={section.items}
              />
            ))}
            {unusedSections.length > 0 && (
              <Placeholder to="/handbook/create-section">
                Add a section
              </Placeholder>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

Overview.action = async ({ request }: ActionFunctionArgs) => {
  const { intent, ...data } = await request.json();

  if (intent === 'handbook-setup') {
    await api({
      method: 'POST',
      url: '/api/form_captures/save/handbook_setup',
      body: {
        data,
      },
    });
    try {
      return await api({
        method: 'POST',
        url: '/api/handbook/draft',
      });
    } catch (err) {
      const response = err as Response;
      if (response.status === 409) {
        // already exists, continue anyway
        return { ok: true, intent };
      } else {
        throw err;
      }
    }
  }

  return { ok: false, intent };
};
