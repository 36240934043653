import { ClipboardCopyIcon } from '@heroicons/react/outline';
import { Button } from '@mosey/components/buttons/Button';
import { useCopy } from '@mosey/components/hooks/useCopy';
import {
  useIsNoticesAndPostersTask,
  useTaskSourceRequirement,
} from '../utils/hooks';
import { useUser } from '../../../hooks/useUser';

export const NoticesCopyButton = () => {
  const {
    legal_entity: { notice_url: noticeUrl },
  } = useUser();
  const { location } = useTaskSourceRequirement();
  const isNotices = useIsNoticesAndPostersTask();
  const [handleCopy, isCopied] = useCopy(
    `${noticeUrl}?region=${location.name}`,
  );

  if (isNotices) {
    return (
      <Button
        onClick={handleCopy}
        size="xlarge"
        variant="secondary"
        leftIcon={<ClipboardCopyIcon className="size-5" />}
      >
        {isCopied ? `Copied` : `Copy`} {location.name} Notice Board Link
      </Button>
    );
  }

  return null;
};
