import {
  ComponentPropsWithRef,
  ElementRef,
  ElementType,
  ForwardedRef,
  forwardRef,
} from 'react';
import { clsx } from 'clsx';

export type CardWrapperProps<T extends ElementType> = {
  as?: T;
} & ComponentPropsWithRef<T>;

export const CardWrapper = forwardRef(function CardWrapper<
  T extends ElementType,
>(
  { as: Component = 'div', className, ...rest }: CardWrapperProps<T>,
  ref: ForwardedRef<ElementRef<T>>,
) {
  return (
    <Component
      ref={ref}
      /**
       * NOTE: Important! Do not add any Tailwind utility classes here since
       * they can't be linted alongside what's passed in as a prop and there may
       * be conflicts.
       */
      className={clsx('group/task-summary-card', className)}
      {...rest}
    />
  );
});
