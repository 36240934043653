import {
  LibraryIcon,
  OfficeBuildingIcon,
  SparklesIcon,
} from '@heroicons/react/outline';
import { LightningBoltIcon, StopwatchIcon } from '../Icons';
import { TaskCardReason } from './types';

type ReasonIconProps = {
  reason: TaskCardReason;
};

export const ReasonIcon = ({ reason }: ReasonIconProps) => {
  let Component;

  switch (reason) {
    case 'setup':
      Component = LightningBoltIcon;
      break;

    case 'new-requirement':
      Component = LibraryIcon;
      break;

    case 'due':
      Component = StopwatchIcon;
      break;

    case 'account':
      Component = OfficeBuildingIcon;
      break;

    case 'suggestion':
    default:
      Component = SparklesIcon;
  }

  return <Component className="size-3 min-w-3 text-zinc-600" />;
};
