import { Handbook } from '@mosey/handbook';
import { api } from '../../../utils/fetchApi';
import { LoaderFunctionArgs, useSearchParams } from 'react-router-dom';
import { HandbookPreviewHeader } from './HandbookPreviewHeader';

export const HandbookPreviewWrapper = () => {
  const [searchParams] = useSearchParams();
  return (
    <div className="h-screen overflow-y-auto">
      <Handbook
        getSectionUrl={(sectionId, policyId) =>
          policyId
            ? `/handbook-preview/${sectionId}?${searchParams.toString()}#${policyId}`
            : `/handbook-preview/${sectionId}?${searchParams.toString()}`
        }
        Header={HandbookPreviewHeader}
      />
    </div>
  );
};

HandbookPreviewWrapper.loader = ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const region = url.searchParams.get('region') || '';
  return api({
    url: `/api/handbook/preview?region=${region}`,
    method: 'GET',
  });
};
