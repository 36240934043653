import { Policy as TPolicy } from '../../types/policy-handbook';
import { PolicyContentRenderer } from '@mosey/components/handbook/PolicyContentRenderer';
import { PolicyFullDetails } from '@mosey/components/handbook/PolicyFullDetails';
import { PolicyResources } from './PolicyResources';
import { PolicyTitle } from './PolicyTitle';
import { useMemo } from 'react';
import { fillPolicyContent } from '@mosey/utils/templates/render';

type PolicyProps = {
  policy: TPolicy;
};

export const Policy = ({ policy }: PolicyProps) => {
  const {
    main,
    resources,
    full_details: fullDetails,
  } = useMemo(
    () => fillPolicyContent(policy.content, { '-1': policy.attribute_values }),
    [policy],
  );
  return (
    <div
      className="flex flex-col items-stretch gap-3 overflow-hidden"
      id={policy.slug}
    >
      <PolicyTitle>{policy.title}</PolicyTitle>
      <div className="flex flex-col gap-4 text-zinc-700">
        {main.map((content, index) => (
          <PolicyContentRenderer key={index} content={content} />
        ))}
      </div>
      {resources && resources.length > 0 && (
        <PolicyResources resources={resources} />
      )}
      {fullDetails && <PolicyFullDetails fullDetails={fullDetails} />}
    </div>
  );
};
