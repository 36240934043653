import { Link, LinkProps } from 'react-router-dom';
import { clsx } from 'clsx';
import { PlusCircleIcon } from '@heroicons/react/outline';

interface PlaceholderBaseProps {
  size?: 'base' | 'small';
  fitToHeight?: boolean;
  fitToWidth?: boolean;
  variant?: 'static' | 'dynamic';
}

type PlaceholderProps = PlaceholderBaseProps & {
  as?: typeof Link;
} & Omit<LinkProps, 'className'>;

type PlaceholderPolymorphicProps<T extends React.ElementType> =
  PlaceholderBaseProps & {
    as: T;
  } & Omit<React.ComponentPropsWithoutRef<T>, 'className'>;

export const Placeholder = <T extends React.ElementType>({
  as,
  size = 'base',
  variant = 'static',
  fitToHeight,
  fitToWidth,
  ...props
}: PlaceholderProps | PlaceholderPolymorphicProps<T>) => {
  let Component;

  if (as) {
    Component = as;
  } else {
    Component = Link;
  }

  return (
    <Component
      {...props}
      className={clsx(
        'group flex items-center gap-x-3 rounded-md border border-dashed border-sage-500 bg-sage-100 px-4 text-gray-700 transition-colors hover:border-sage-600 hover:bg-sage-200 hover:text-black focus-visible:outline-2 focus-visible:outline-rose-700',
        variant === 'static' && 'justify-center whitespace-nowrap',
        variant === 'dynamic' && 'justify-start',
        size === 'base' && 'font-bold',
        size === 'small' && 'text-sm font-semibold',
        fitToHeight && 'h-full',
        fitToWidth && 'w-full',
        !fitToHeight && 'py-4',
      )}
    >
      {'children' in props && props.children}
      <PlusCircleIcon
        className={clsx(
          'shrink-0 text-sage-600 transition-colors group-hover:text-sage-700',
          size === 'base' && 'size-6',
          size === 'small' && 'size-5',
        )}
      />
    </Component>
  );
};
