import { Switch, SwitchProps } from '@headlessui/react';

type ToggleProps = Omit<SwitchProps, 'className'>;

export const Toggle = (props: ToggleProps) => {
  return (
    <Switch
      {...props}
      className="group relative flex h-[18px] w-[30px] shrink-0 cursor-pointer items-center rounded-full border-1 border-gray-200 bg-white transition-colors duration-200 ease-in-out focus-visible:outline-2 focus-visible:outline-teal-600 data-[checked]:border-teal-700 data-[checked]:bg-teal-700"
    >
      <span
        aria-hidden="true"
        className="pointer-events-none ml-0.5 inline-block size-3 translate-x-0 rounded-full border-1 border-gray-300 bg-gray-200 shadow-lg ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-3 group-data-[checked]:border-none group-data-[checked]:bg-teal-100"
      />
    </Switch>
  );
};
