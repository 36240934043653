import {
  ActionFunction,
  Navigate,
  useFetcher,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { CheckIcon, ErrorIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { api } from '../utils/fetchApi';
import { useLegalEntityRegions } from '../hooks/useUser';

interface ActionResponse {
  errors: Record<string, string>;
  resolved: boolean;
}

export const action: ActionFunction = async ({
  params: { locationId },
}): Promise<ActionResponse> => {
  try {
    await api({
      url: `/api/legal_entity/locations/${locationId!.toLowerCase()}/certificate_of_good_standing`,
      method: 'POST',
      body: {},
    });
  } catch (error) {
    let submitError = 'Something went wrong, please try again.';

    if (error instanceof Response) {
      const parsedError = await error.json();
      submitError = parsedError.message;
    }

    return {
      errors: {
        submit: submitError,
      },
      resolved: false,
    };
  }

  return {
    errors: {},
    resolved: true,
  };
};

export const Component = () => {
  const navigate = useNavigate();
  const fetcher = useFetcher<ActionResponse>();
  const { locationId } = useParams();
  const regions = useLegalEntityRegions();
  const region = regions.find(({ code }) => code === locationId?.toUpperCase());

  if (!region) {
    return <Navigate to="../" />;
  }

  return (
    <div className="bg-gray-50 px-10 py-6">
      <div className="flex items-center justify-center border bg-white">
        {fetcher.data?.resolved ? (
          <div className="flex h-64 flex-col items-center justify-center">
            <div className="size-10 rounded-full bg-green-600 p-1 text-white">
              <CheckIcon />
            </div>

            <h2 className="text-2xl font-bold text-green-600">Success</h2>

            <p className="ml-1 text-gray-500">
              Your Certificate of Good Standing request is on it&apos;s way!
            </p>
          </div>
        ) : (
          <fetcher.Form className="my-8 w-96 py-8" method="POST">
            <div className="mb-4 border-b pb-4">
              <h2 className="text-2xl font-bold tracking-tight">
                Request a {region.name} Certificate of Good Standing
              </h2>
            </div>
            <p className="mb-4 text-gray-600">
              You can use Mosey to request a Certificate of Good the state of{' '}
              {region.name}. We&apos;ll notify you once the request is complete
              with a copy of your certificate.
            </p>
            <div className="my-10">
              <Button
                type="submit"
                size="large"
                isLoading={fetcher.state === 'submitting'}
              >
                Submit
              </Button>
              <Button
                type="button"
                size="large"
                variant="secondary"
                className="ml-4"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              {fetcher.data?.errors.submit && (
                <div className="mt-2 flex items-center text-xs text-red-600">
                  <span>
                    <ErrorIcon className="mr-1 size-4" />
                  </span>
                  <div>
                    <p>{`${
                      fetcher.data?.errors.submit || 'Something went wrong.'
                    }`}</p>
                  </div>
                </div>
              )}
            </div>
          </fetcher.Form>
        )}
      </div>
    </div>
  );
};
