export enum ResolverType {
  Assessment = 'assessment',
  Setup = 'setup',
  Requirement = 'requirement',
  Question = 'question',
  Upcoming = 'upcoming',
  Overdue = 'overdue',
  Review = 'review',
}

export type ResolverRouteParams = {
  resolverType?: ResolverType;
  countryId?: 'usa';
  // TODO: Type this as USStateAbbrev
  locationId?: string;
};

export type LocationResolverRouteParams = ResolverRouteParams & {
  countryId: 'usa';
  // TODO: Type this as USStateAbbrev
  locationId: string;
};

export type TaskRouteParams = ResolverRouteParams & {
  taskId: string;
};
