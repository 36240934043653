import {
  LoaderFunction,
  Navigate,
  json,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from 'react-router-dom';
import { TaskStatus, TaskType } from '@mosey/api-types';
import {
  useNextTask,
  useTaskUrl,
  useNextResolverUrl,
  useTask,
  useTaskSourceRequirement,
} from '../utils/hooks';
import { api } from '../../../utils/fetchApi';
import { AutomationTypeEnum, Requirement } from '../../../types';
import { AutomationView } from '../../AutomationView';
import { LocalPayrollSetup } from '../../LocalPayrollSetup';
import { TaskHeader } from '../common/TaskHeader';
import { TaskContainer } from './TaskContainer';
import { useState } from 'react';
import { TaskSummary } from './TaskSummary';
import { Loading } from '../../Loading';

export const loader: LoaderFunction = async ({ params: { taskId } }) => {
  // First, check if we have dynamic forms for this automation
  let dynamicAutomationResponse;

  const requirement = api({
    url: `/api/requirements/${taskId}`,
    method: 'POST',
  });

  try {
    dynamicAutomationResponse = await api({
      method: 'GET',
      url: `/api/automation/${taskId}`,
    });
  } catch (error) {
    return null;
  }

  if (dynamicAutomationResponse.status === 302) {
    return dynamicAutomationResponse;
  }

  const { forms } = await dynamicAutomationResponse.json();

  if (forms && forms.length) {
    // Handle dynamic form automation
    return {
      formType: AutomationTypeEnum.DynamicForm,
      requirement: await (await requirement).json(),
    };
  } else {
    // If no dynamic form, find which automation type this is
    const automationResponse = await api({
      method: 'POST',
      url: `/api/requirements/${taskId}/automate`,
    });

    const { automation_type: automationType } = await automationResponse.json();

    return json({
      formType: automationType,
      requirement: await (await requirement).json,
    });
  }
};

export const Component = () => {
  const revalidator = useRevalidator();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const navigate = useNavigate();
  const loaderData = useLoaderData() as {
    formType: AutomationTypeEnum;
    requirement: Requirement;
  } | null;
  const { status } = useTask();
  const {
    is_automatable: isAutomatable,
    type,
    location,
  } = useTaskSourceRequirement();
  const taskUrl = useTaskUrl();
  const nextTask = useNextTask();
  const nextResolverUrl = useNextResolverUrl();

  const onSubmitSuccess = () => {
    revalidator.revalidate();
    setSubmitSuccess(true);
  };

  const onEnterLater = () => {
    navigate(nextResolverUrl || '../');
  };

  if (submitSuccess === true) {
    if (revalidator.state === 'idle') {
      return <TaskSummary />;
    }

    return (
      <div className="w-full">
        <Loading />
      </div>
    );
  }

  if (
    loaderData === null ||
    type !== TaskType.requirement ||
    status === TaskStatus.done ||
    !isAutomatable
  ) {
    return <Navigate to={taskUrl} replace />;
  }

  const { formType, requirement } = loaderData;

  return (
    <TaskContainer>
      <TaskHeader />

      <p>
        Please provide some additional information about your business to
        automate this.
      </p>

      <section className="mt-2 max-w-md">
        {formType === AutomationTypeEnum.DynamicForm && (
          <AutomationView
            requirement={requirement}
            onAutomationComplete={onSubmitSuccess}
            onEnterLater={nextTask ? onEnterLater : undefined}
            isFullWidth
            scrollOnMount={false}
            showHeading={false}
          />
        )}
        {formType === AutomationTypeEnum.LocalPayrollSetup && (
          <LocalPayrollSetup
            onSubmitSuccess={onSubmitSuccess}
            locationId={location.id}
            requirement={requirement}
            isFullWidth
          />
        )}
      </section>
    </TaskContainer>
  );
};
