import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../hooks/useUser';
import { isLocationDetailPreview } from './tasks-framework/utils';

export const LocationDetailIndex = () => {
  const user = useUser();

  // TODO: Remove this once Tasks Overview is live on Location Detail
  if (user.role === 'admin' && isLocationDetailPreview()) {
    return <Navigate to="overview" replace />;
  }

  return <Outlet />;
};
