import { clsx } from 'clsx';

export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'graySecondary'
  | 'grayTertiary'
  | 'actionSuccess'
  | 'indigoPrimary';

export type BaseButtonProps = {
  isDisabled?: boolean;
  variant?: ButtonVariants;
  as?: React.ElementType;
};

type Props<T extends React.ElementType> =
  React.PropsWithChildren<BaseButtonProps> &
    React.ComponentPropsWithoutRef<T> &
    BaseButtonProps;

const classes = {
  variant: {
    primary:
      'bg-rose-700 text-white border-rose-700 hover:bg-rose-800 ring-offset-1 active:bg-rose-900 focus-visible:ring-rose-700 focus-visible:outline-none',
    secondary:
      'bg-white text-gray-500 border-sage-600 hover:bg-sage-200 ring-offset-1 active:bg-sage-400 focus-visible:ring-rose-700 focus-visible:outline-none',
    graySecondary:
      'bg-sage-50 text-sage-600 border-gray-300 hover:bg-sage-200 active:bg-sage-400',
    grayTertiary:
      'bg-sage-300 text-gray-500 border-sage-600 hover:bg-sage-400 active:bg-sage-500',
    actionSuccess:
      'bg-lime-500 text-white border-lime-500 ring-offset-1 ring-lime-500',
    indigoPrimary:
      'bg-indigo-600 text-white border-indigo-600 hover:bg-indigo-700 ring-offset-1 active:bg-indigo-800 focus-visible:ring-indigo-600 focus-visible:outline-none',
  },
  disabled:
    'opacity-50 cursor-default pointer-events-none focus-visible:ring-0',
};

/**
 * Base button used to compose Button and IconButton components
 */
export const BaseButton = <T extends React.ElementType>({
  children,
  className,
  isDisabled = false,
  variant = 'primary',
  as,
  ...props
}: Props<T>) => {
  const Element = as || 'button';

  return (
    <Element
      className={clsx(
        'transition-bg inline-flex items-center justify-center whitespace-nowrap border-1 font-bold duration-150 focus-visible:ring-1',
        {
          [classes.variant[variant]]: !!variant,
          [classes.disabled]: props?.disabled || isDisabled,
        },
        className,
      )}
      {...props}
    >
      {children}
    </Element>
  );
};
