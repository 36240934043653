import { NavItem } from '@mosey/components/navigation/NavItem';
import { SubNavItem } from '@mosey/components/navigation/SubNavItem';
import { PolicySection } from '../../types/policy-handbook';
import { useContext } from 'react';
import { NavigationContext } from '../../contexts/NavigationContext';
import * as icons from '@heroicons/react/outline';

type AppNavigationProps = {
  sections: Array<PolicySection>;
  getSectionUrl: (sectionId: string, policyId?: string) => string;
};

export const HandbookNavigation = ({
  sections,
  getSectionUrl,
}: AppNavigationProps) => {
  const { setIsSidebarOpen } = useContext(NavigationContext);
  return (
    <nav className="flex flex-1 flex-col overflow-y-auto px-4">
      <ul className="mt-[26px] flex flex-1 flex-col gap-2 pb-4 text-gray-600">
        {sections.map((section) => (
          <NavItem
            key={section.id}
            name={section.title}
            to={getSectionUrl(section.id)}
            // eslint-disable-next-line import/namespace
            Icon={icons[section.icon] || icons.FolderIcon}
            onClick={() => setIsSidebarOpen(false)}
          >
            {section.items.map((item) => (
              <SubNavItem
                key={item.slug}
                name={item.title!}
                to={getSectionUrl(section.id, item.slug)}
                onClick={() => setIsSidebarOpen(false)}
              />
            ))}
          </NavItem>
        ))}
      </ul>
    </nav>
  );
};
