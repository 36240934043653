import { FormSpec, Region } from '../../types';
import { components, paths } from '@mosey/api-types';
import { TaskCardStatus } from '@mosey/components/layout/types';

export type PolicyContent = components['schemas']['PolicyContent'];

export type PolicySection = components['schemas']['PolicySection'];

export type PolicyCategory = components['schemas']['PolicyCategory'];

export type PolicyScope = {
  id: number;
  city_name: string | null;
  zip_code_group_id: number | null;
  region_id: number | null;
  region: Region | null;
};

export type Policy = components['schemas']['Policy'];

export type PolicyInstance = components['schemas']['PolicyInstance'];

export type Handbook = {
  id: string;
  is_current_draft: boolean;
  is_active: boolean;
  is_publishable: boolean;
  policy_instances: Array<PolicyInstance>;
  created_at: string;
  updated_at: string;
  published_at: string | null;
};

export type HandbookDataLoader = {
  draft?: Handbook;
  published?: Handbook;
  policies: Array<Policy>;
  stats: components['schemas']['Stats'];
  sections: Array<PolicySection>;
};

export type PolicySectionLoader = Array<{
  policy: Policy;
  form: FormSpec;
}>;

export type AttributeValues =
  paths['/api/handbook/policy_section/{policy_section_public_id}/attribute_values']['get']['responses']['200']['content']['application/json'];

export type PublishableChange = {
  type: 'adopted' | 'updated';
  instance: PolicyInstance;
};

export type DetailedPolicy = Policy & {
  draftInstance?: PolicyInstance;
  publishedInstance?: PolicyInstance;
  status: PolicyItemStatus;
};

export type PolicyItemStatus =
  | 'adoptable'
  | 'updateable'
  | 'updated'
  | 'adopted'
  | 'published';

export const POLICY_TO_TASK_STATUS: Record<PolicyItemStatus, TaskCardStatus> = {
  adoptable: 'todo',
  updateable: 'update-available',
  updated: 'updated',
  adopted: 'done',
  published: 'done',
};

type BasePolicyItem = {
  slug: string;
  title: string;
  order: number;
  status: PolicyItemStatus;
  sectionId: string;
  regionDescription?: string;
};

export type PolicyItem = BasePolicyItem & {
  type: 'policy';
  policy: DetailedPolicy;
};

export type CategoryItem = BasePolicyItem & {
  type: 'category';
  policies: Array<DetailedPolicy>;
  groups: Array<PolicyGroup>;
  customPolicy?: DetailedPolicy;
};

export type PolicyGroup = {
  policies: Array<DetailedPolicy>;
  regions: Array<string>;
};

export type PolicyListItem = PolicyItem | CategoryItem;
