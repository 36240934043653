import { TruncatedList } from '@mosey/components/layout/TruncatedList';
import { CardWrapper } from '@mosey/components/layout/CardWrapper';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { useLegalEntityRegions } from '../../../../hooks/useUser';
import { isTaskSetupRelated } from '../../utils';
import {
  useTasksOverviewData,
  useTasksOverviewLocationId,
} from '../../utils/hooks';

export const AssessmentCollection = () => {
  const locationId = useTasksOverviewLocationId();
  const regions = useLegalEntityRegions();
  const { tasks, summary } = useTasksOverviewData();
  const assessmentAlerts = locationId
    ? []
    : regions.filter((region) => summary[region.code].is_assessment);
  const assessmentTasks = locationId
    ? tasks.filter(
        (task) => task.source.type === 'question' && isTaskSetupRelated(task),
      )
    : [];

  return (
    <TruncatedList
      title="Answer Questions"
      body="Your responses generate setup tasks."
      itemLabel={locationId ? 'tasks' : 'locations'}
      as="nav"
      aria-live="polite"
      isStacked={locationId !== null}
    >
      {locationId
        ? assessmentTasks.map(({ id, title, status }) => {
            return (
              <CardWrapper key={id} as="li">
                <TaskSummaryCard
                  title={title}
                  status={status === 'deferred' ? 'todo' : status}
                  variant="nav"
                  size="medium"
                  to={`/locations/usa/${locationId}/resolver/assessment/tasks/${id}`}
                />
              </CardWrapper>
            );
          })
        : assessmentAlerts.map((region) => {
            return (
              <CardWrapper key={region.code} as="li">
                <TaskSummaryCard
                  title={region.name}
                  status="question-collection"
                  variant="collection"
                  size="medium"
                  to={`/locations/usa/${region.code.toLowerCase()}/resolver/assessment`}
                  aria-label={`${region.name} assessment`}
                />
              </CardWrapper>
            );
          })}
    </TruncatedList>
  );
};
