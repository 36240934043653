import { NavLink, NavLinkProps, useNavigation } from 'react-router-dom';
import { TabLike, TabLikeProps } from '../layout/TabLike';

type PageNavLinkProps = Omit<NavLinkProps, 'className'> &
  Pick<TabLikeProps, 'icon' | 'count' | 'variant'>;

export const PageNavLink = ({
  children,
  variant = 'base',
  icon,
  count,
  ...rest
}: PageNavLinkProps) => {
  const navigation = useNavigation();
  const isNavigatingAway =
    navigation.state !== 'idle' &&
    navigation.location?.pathname !== location.pathname;

  return (
    <NavLink className="group/tab-like outline-none" {...rest}>
      {(bag) => {
        const { isActive, isPending } = bag;

        return (
          <TabLike
            isActive={isPending || (isActive && !isNavigatingAway)}
            variant={variant}
            count={count}
            icon={icon}
          >
            {typeof children === 'function' ? children(bag) : children}
          </TabLike>
        );
      }}
    </NavLink>
  );
};
