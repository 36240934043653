import { ReactNode } from 'react';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { USStateName } from '@mosey/utils/constants/us-states';
import { components } from '@mosey/api-types';
import { CopyablePolicyPreview } from '../../../components/requirement/CopyablePolicyPreview';
import {
  useTaskPolicyInstances,
  useTaskSourceRequirement,
} from '../utils/hooks';
import { useIsHandbookEnabled } from '../../handbook/hooks/useIsHandbook';

type TaskRemediation = components['schemas']['TaskRemediation'];

type AutomationStepSideEffectsProps = {
  remediation: TaskRemediation;
};

export const AutomationStepSideEffects = ({
  remediation,
}: AutomationStepSideEffectsProps) => {
  const taskPolicyInstances = useTaskPolicyInstances();
  const isHandbookEnabled = useIsHandbookEnabled();
  const source = useTaskSourceRequirement();
  let content: ReactNode | null = null;
  if (
    remediation.paths.findIndex((path) => path.type === 'policy-generation') >=
      0 &&
    taskPolicyInstances.length > 0
  ) {
    if (isHandbookEnabled) {
      content = (
        <ul className="w-full">
          {taskPolicyInstances.map((policyInstance) => (
            <li key={policyInstance.id}>
              <TaskSummaryCard
                target="_blank"
                variant="card"
                to={`/handbook/section/${policyInstance.policy_section.id}/policies#${policyInstance.slug}`}
                status="done"
                title={policyInstance.title!}
                region={
                  USStateName[source.location.id as keyof typeof USStateName]
                }
                reason={{
                  title: 'State Compliance',
                  type: 'new-requirement',
                }}
              />
            </li>
          ))}
        </ul>
      );
    } else {
      content = (
        <div className="flex flex-col gap-4">
          {taskPolicyInstances.map((instance) => (
            <CopyablePolicyPreview
              key={instance.id}
              policyInstance={instance}
            />
          ))}
        </div>
      );
    }
  }

  if (content) {
    return (
      <div className="relative pl-6">
        <div
          aria-hidden="true"
          className="absolute inset-y-0 ml-1.5 block w-1.5 bg-indigo-50"
        />
        <div className="pb-2 pl-6 pt-4">{content}</div>
      </div>
    );
  } else {
    return null;
  }
};
