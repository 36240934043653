import { Fragment } from 'react';
import { clsx } from 'clsx';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';

type TruncatedListProps<T extends React.ElementType = 'article'> = {
  as?: T;
  title: React.ReactNode;
  body?: React.ReactNode;
  extra?: React.ReactNode;
  children?: React.ReactNode[];
  isStacked?: boolean;
  amount?: number;
  itemLabel?: string;
  showIfEmpty?: boolean;
} & Omit<React.ComponentPropsWithoutRef<T>, 'className' | 'children'>;

export const TruncatedList = <T extends React.ElementType = 'article'>({
  as,
  title,
  body,
  extra,
  children = [],
  isStacked = false,
  amount = 3,
  itemLabel,
  showIfEmpty = false,
  ...props
}: TruncatedListProps<T>) => {
  const Component = as || 'article';
  const moreItemCount = children.length - amount;
  const showMoreButton = moreItemCount > 2;

  return (
    <Component className="contents" {...props}>
      {(children.length > 0 || showIfEmpty) && (
        <div className="space-y-2">
          <div className="space-y-0.5">
            <h2 className="font-semibold text-zinc-800">{title}</h2>
            {body && <p className="text-sm text-zinc-600">{body}</p>}
          </div>
          {extra}

          {children.length > 0 && (
            <Disclosure as="div" className="space-y-3">
              {({ open }) => (
                <>
                  <ul className={clsx(!isStacked && 'space-y-2')}>
                    {children.slice(0, showMoreButton ? amount : undefined)}

                    {showMoreButton && (
                      <DisclosurePanel as={Fragment}>
                        <>{children.slice(amount)}</>
                      </DisclosurePanel>
                    )}
                  </ul>

                  {showMoreButton && (
                    <DisclosureButton className="relative z-10 w-full rounded-full border border-gray-200 bg-gray-50 p-1 text-sm text-zinc-600 outline-none hover:border-teal-400 hover:bg-teal-300 hover:text-zinc-700 hover:shadow-sm focus-visible:shadow-sm focus-visible:ring-2 focus-visible:ring-rose-700">
                      {open
                        ? 'Show less'
                        : `Show ${moreItemCount} more ${itemLabel ? itemLabel : 'items'}`}
                    </DisclosureButton>
                  )}
                </>
              )}
            </Disclosure>
          )}
        </div>
      )}
    </Component>
  );
};
