import { RefObject, useState } from 'react';
import { copyHtmlToClipboard } from '@mosey/utils/data/clipboard';

export function useCopy(
  valueToCopy: string | RefObject<HTMLElement>,
  timeoutMs = 1000,
): [() => void, boolean] {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const onCopy = () => {
    if (typeof valueToCopy === 'string') {
      navigator.clipboard.writeText(valueToCopy);
    } else if (valueToCopy && valueToCopy.current) {
      copyHtmlToClipboard(
        valueToCopy.current.innerHTML,
        valueToCopy.current.innerText,
      );
    }
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), timeoutMs);
  };

  return [onCopy, isCopied];
}
