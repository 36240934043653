import { FunctionComponent, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router';
import { Button } from '@mosey/components/buttons/Button';
import {
  Section,
  SectionHeading,
  Confirmation,
  BatchApiStatusHandler,
} from '../components';
import { Requirement } from '../types';
import { cleanIntakeDates } from '../utils/intake';
import { fetchApi } from '../utils/fetchApi';
import { ApiStatus, IApiData } from '../utils/types';
import { Renderer, formSpecToRendererConfig } from '../components/forms';
import DefaultSalesTaxSetupSpec from '../components/forms/specs/default_sales_tax_setup.json';
import { useBatchApi } from '../hooks';
import { FormError } from '@mosey/components/forms/FormError';

type SalesTaxSetupProps = {
  locationId: string;
  requirement: Requirement;
  onSubmitSuccess?: () => void;
  onEnterLater?: () => void;
};

export const SalesTaxSetup: FunctionComponent<SalesTaxSetupProps> = ({
  requirement,
  locationId,
  onSubmitSuccess,
  onEnterLater,
}) => {
  const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
  const [isSubmitSuccessful, setSubmitSuccessful] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const loadedSpec = DefaultSalesTaxSetupSpec;
  const formMethods = useForm();
  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const onSubmit = (data: unknown) => {
    setHasSubmitError(false);
    setIsSubmitting(true);

    const payload = cleanIntakeDates(data);

    // Workflow instance automation
    fetchApi({
      url: `/api/form_captures/${locationId}_sales_tax`,
      method: 'POST',
      body: {
        data: payload,
      },
    }).then(({ status }: IApiData) => {
      if (status !== ApiStatus.Success) {
        setHasSubmitError(true);
      } else {
        // Create and start workflow for requirements
        fetchApi({
          url: `/api/workflows`,
          method: 'POST',
          body: {
            data_ids: [requirement.data_id],
            region_code: locationId,
          },
        }).then(({ status: status2 }: IApiData) => {
          if (status2 !== ApiStatus.Success) {
            setHasSubmitError(true);
          } else if (onSubmitSuccess) {
            onSubmitSuccess();
          } else {
            setSubmitSuccessful(true);
          }
        });
      }
      setIsSubmitting(false);
    });
  };
  return (
    <Section className="flex flex-col items-center">
      {isSubmitSuccessful ? (
        <Confirmation
          heading="Success"
          description="Your request is on its way"
        />
      ) : (
        <>
          <SectionHeading className="w-96 pb-4" text={loadedSpec.title} />
          <FormProvider {...formMethods}>
            <form className="my-8 w-96" onSubmit={handleSubmit(onSubmit)}>
              <Renderer
                config={formSpecToRendererConfig(loadedSpec)}
                errors={errors}
              />
              <p className="mt-4 flex flex-col">
                {onEnterLater && (
                  <Button
                    type="button"
                    variant="grayTertiary"
                    size="large"
                    isFullWidth
                    onClick={onEnterLater}
                  >
                    Enter later
                  </Button>
                )}

                <Button type="submit" size="large" isLoading={isSubmitting}>
                  Submit
                </Button>
                {hasSubmitError && <FormError />}
              </p>
            </form>
          </FormProvider>
        </>
      )}
    </Section>
  );
};

export const SalesTaxSetupView: FunctionComponent = () => {
  const { locationId, requirementId } = useParams<Record<string, string>>();
  if (!locationId || !requirementId) {
    throw Error('Location or requirement missing');
  }

  const batchResponse = useBatchApi([
    {
      url: `/api/requirements/${requirementId}`,
      method: 'POST',
    },
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const componentPropsFn = ([reqResponse]: any[]): SalesTaxSetupProps => {
    return {
      requirement: reqResponse,
      locationId,
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={SalesTaxSetup}
      componentProps={componentPropsFn}
    />
  );
};

export default SalesTaxSetupView;
