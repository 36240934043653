import { Button } from '@mosey/components/buttons/Button';
import { GuidedStepContentProps } from './types';

export const VisitWebsiteStep = ({
  login,
  nextStep,
}: GuidedStepContentProps) => {
  return (
    <div className="mt-8 flex flex-col items-start gap-8">
      <h5 className="text-2xl font-semibold tracking-tight">
        Visit {login.account_name}
      </h5>
      <div className="flex flex-col gap-4">
        <p className="max-w-xl">
          Click the button below to open the {login.account_name} website in a
          new tab. Follow the steps here to gather your credentials needed for
          each step.
        </p>
        <a
          href={login.login_url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-teal-700 underline hover:text-teal-900"
        >
          Open {login.account_name}
        </a>
      </div>
      <Button onClick={nextStep}>Next</Button>
    </div>
  );
};
