import { TruncatedList } from '@mosey/components/layout/TruncatedList';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import {
  useIsLocationDetail,
  useTasksOverviewData,
  useTasksOverviewLocationId,
} from '../../utils/hooks';
import { isTaskSetupRelated, isLocationDetailPreview } from '../../utils';
import { useLegalEntityRegions } from '../../../../hooks/useUser';
import { CardWrapper } from '@mosey/components/layout/CardWrapper';
import { Navigate } from 'react-router-dom';

export const SetupCollection = () => {
  const locationId = useTasksOverviewLocationId();
  const regions = useLegalEntityRegions();
  const isLocationDetail = useIsLocationDetail();
  const locationDetailPreview = isLocationDetailPreview();
  const { tasks, summary } = useTasksOverviewData();
  const setupAlerts = locationId
    ? []
    : regions.filter((region) => summary[region.code].is_setup);
  const setupTasks = locationId
    ? tasks.filter(
        (task) =>
          task.source.type === 'requirement' && isTaskSetupRelated(task),
      )
    : [];

  if (locationId && isLocationDetail && locationDetailPreview) {
    const currentRegion = regions.find(
      (region) => region.code.toLowerCase() === locationId.toLowerCase(),
    );
    if (!currentRegion) {
      return <Navigate to="/home" replace />;
    }
    const regionName = currentRegion.name;

    const payrollTasks = tasks.filter(
      (task) =>
        'navigator_section' in task.source &&
        task.source.navigator_section === 'payroll',
    );
    const hrTasks = tasks.filter(
      (task) =>
        'navigator_section' in task.source &&
        task.source.navigator_section === 'hr',
    );
    const registrationTasks = tasks.filter(
      (task) =>
        'navigator_section' in task.source &&
        task.source.navigator_section === 'registration',
    );
    const corporateTaxTasks = tasks.filter(
      (task) =>
        'navigator_section' in task.source &&
        task.source.navigator_section === 'corporate-tax',
    );
    const salesTaxTasks = tasks.filter(
      (task) =>
        'navigator_section' in task.source &&
        task.source.navigator_section === 'tax',
    );
    return (
      <>
        <TruncatedList
          title={'Payroll Setup'}
          key={'payroll'}
          body={
            payrollTasks.length > 0
              ? undefined
              : `You have no employees in ${regionName}`
          }
          itemLabel={'tasks'}
          as="nav"
          aria-live="polite"
          isStacked={true}
          showIfEmpty={true}
        >
          {payrollTasks.map(({ id, title, status }) => {
            return (
              <CardWrapper as="li" key={id}>
                <TaskSummaryCard
                  title={title}
                  status={status === 'deferred' ? 'todo' : status}
                  variant="nav"
                  size="medium"
                  to={`/requirement/${id}`}
                />
              </CardWrapper>
            );
          })}
        </TruncatedList>
        <TruncatedList
          title={'HR Setup'}
          key={'hr'}
          body={
            hrTasks.length > 0
              ? undefined
              : `You have no employees in ${regionName}`
          }
          itemLabel={'tasks'}
          as="nav"
          aria-live="polite"
          isStacked={true}
          showIfEmpty={true}
        >
          {hrTasks.map(({ id, title, status }) => {
            return (
              <CardWrapper as="li" key={id}>
                <TaskSummaryCard
                  title={title}
                  status={status === 'deferred' ? 'todo' : status}
                  variant="nav"
                  size="medium"
                  to={`/requirement/${id}`}
                />
              </CardWrapper>
            );
          })}
        </TruncatedList>
        <TruncatedList
          title={'Registration'}
          key={'registration'}
          body={
            registrationTasks.length > 0
              ? undefined
              : `You are not doing business in ${regionName}`
          }
          itemLabel={'tasks'}
          as="nav"
          aria-live="polite"
          isStacked={true}
          showIfEmpty={true}
        >
          {registrationTasks.map(({ id, title, status }) => {
            return (
              <CardWrapper as="li" key={id}>
                <TaskSummaryCard
                  title={title}
                  status={status === 'deferred' ? 'todo' : status}
                  variant="nav"
                  size="medium"
                  to={`/requirement/${id}`}
                />
              </CardWrapper>
            );
          })}
        </TruncatedList>
        <TruncatedList
          title={'Corporate Franchise Tax Setup'}
          key={'corporate-tax'}
          body={
            corporateTaxTasks.length > 0
              ? undefined
              : `You do not have corporate tax nexus in ${regionName}`
          }
          itemLabel={'tasks'}
          as="nav"
          aria-live="polite"
          isStacked={true}
          showIfEmpty={true}
        >
          {corporateTaxTasks.map(({ id, title, status }) => {
            return (
              <CardWrapper as="li" key={id}>
                <TaskSummaryCard
                  title={title}
                  status={status === 'deferred' ? 'todo' : status}
                  variant="nav"
                  size="medium"
                  to={`/requirement/${id}`}
                />
              </CardWrapper>
            );
          })}
        </TruncatedList>
        <TruncatedList
          title={'Sales Tax Setup'}
          key={'tax'}
          body={
            salesTaxTasks.length > 0
              ? undefined
              : `You do not have sales tax nexus in ${regionName}`
          }
          itemLabel={'tasks'}
          as="nav"
          aria-live="polite"
          isStacked={true}
          showIfEmpty={true}
        >
          {salesTaxTasks.map(({ id, title, status }) => {
            return (
              <CardWrapper as="li" key={id}>
                <TaskSummaryCard
                  title={title}
                  status={status === 'deferred' ? 'todo' : status}
                  variant="nav"
                  size="medium"
                  to={`/requirement/${id}`}
                />
              </CardWrapper>
            );
          })}
        </TruncatedList>
      </>
    );
  }

  return (
    <TruncatedList
      title="Complete Setup"
      body="Setup tasks are based on your responses to questions."
      itemLabel={locationId ? 'tasks' : 'locations'}
      as="nav"
      aria-live="polite"
      isStacked={locationId !== null}
    >
      {locationId
        ? setupTasks.map(({ id, title, status }) => (
            <CardWrapper as="li" key={id}>
              <TaskSummaryCard
                title={title}
                status={status === 'deferred' ? 'todo' : status}
                variant="nav"
                size="medium"
                to={`/requirement/${id}`}
              />
            </CardWrapper>
          ))
        : setupAlerts.map((region) => (
            <CardWrapper key={region.code}>
              <TaskSummaryCard
                title={region.name}
                status="setup-collection"
                variant="collection"
                size="medium"
                to={`/locations/usa/${region.code.toLowerCase()}/setup`}
                aria-label={`${region.name} setup`}
              />
            </CardWrapper>
          ))}
    </TruncatedList>
  );
};
