import { Button } from '@mosey/components/buttons/Button';
import { EmailOtpInput } from '../../forms/EmailOtpInput';
import { GuidedStepContentProps } from './types';

export const EmailOtpStep = ({ login, nextStep }: GuidedStepContentProps) => {
  return (
    <div className="mt-8 flex flex-col items-start gap-8">
      <h5 className="text-2xl font-semibold tracking-tight">
        Fetch one-time passcode
      </h5>
      <div className="flex flex-col gap-4">
        <p className="max-w-xl">
          {login.account_name} requires a one-time passcode (OTP). Once you have
          requested the OTP from {login.account_name}, copy it from the field
          below.
        </p>
        <EmailOtpInput
          label="One-Time Passcode"
          name="otp"
          login={login}
          hasMargin={false}
        />
      </div>
      <Button onClick={nextStep}>Next</Button>
    </div>
  );
};
