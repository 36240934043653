import { TasksOverviewIntegrations } from '../TasksOverviewIntegrations';
import { useIsLocationDetail, useTasksOverviewData } from '../../utils/hooks';
import { AssessmentCollection } from './AssessmentCollection';
import { SetupCollection } from './SetupCollection';
import { InProgressCollection } from './InProgressCollection';
import { LegislationCollection } from './LegislationCollection';
import { HandbookTodoCollection } from './HandbookTodoCollection';

export const TasksOverviewSidebar = () => {
  const isLocationDetail = useIsLocationDetail();
  const { connections } = useTasksOverviewData();

  const hasPayrollConnection = connections?.some(
    ({ integration: { type } }) => type === 'payroll',
  );

  return (
    <aside className="isolate flex flex-col gap-y-7 pt-2">
      {!hasPayrollConnection && !isLocationDetail && (
        <TasksOverviewIntegrations />
      )}

      <SetupCollection />
      <AssessmentCollection />
      <HandbookTodoCollection />
      <InProgressCollection />
      <LegislationCollection />

      {hasPayrollConnection && !isLocationDetail && (
        <TasksOverviewIntegrations />
      )}
    </aside>
  );
};
