import { clsx } from 'clsx';

export interface TabLikeProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'className'> {
  children: React.ReactNode;
  isActive?: boolean;
  isFocused?: boolean;
  variant?: 'base' | 'small';
  icon?: React.ReactNode;
  count?: number;
}

/**
 * To use this component in different places, add the class "group/tab-like" to
 * the component in order for this component to recognize hover, focus, etc.
 */
export const TabLike = ({
  children,
  isActive = false,
  isFocused,
  variant = 'base',
  icon,
  count,
  ...rest
}: TabLikeProps) => {
  return (
    <div
      className={clsx(
        'flex items-center gap-1 outline-none',
        isActive && 'border-rose-700 text-rose-700',
        !isActive && 'border-transparent text-sage-700 hover:text-sage-800',
      )}
      {...rest}
    >
      <div
        className={clsx(
          'flex flex-col justify-center border-b-2',
          variant === 'base' && 'h-20',
          variant === 'small' && 'h-11',
          isActive && 'border-rose-700 text-rose-700',
          !isActive && 'border-transparent text-sage-700 hover:text-sage-800',
        )}
      >
        <div
          className={clsx(
            'flex items-center rounded border-2 px-1',
            variant === 'base' && 'gap-x-2 text-lg font-bold',
            variant === 'small' && 'gap-x-1 text-sm font-medium',
            isFocused && 'border-rose-700',
            isFocused === false && 'border-transparent',
            isFocused === undefined &&
              'border-transparent group-focus-visible/tab-like:border-rose-700',
          )}
        >
          {icon && (
            <div
              className={clsx(
                variant === 'base' && 'size-5',
                variant === 'small' && 'size-4',
              )}
              aria-hidden="true"
            >
              {icon}
            </div>
          )}

          {children}
        </div>
      </div>

      {count !== undefined && (
        <div
          className="flex size-6 items-center justify-center rounded-full bg-gray-200 text-center text-xs font-medium"
          aria-label={`(${count} items)`}
        >
          {count}
        </div>
      )}
    </div>
  );
};
