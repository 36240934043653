import { clsx } from 'clsx';
import { SelectorIcon } from '@heroicons/react/outline';
import { LoadingIcon } from '../../Icons';

export interface MenuBaseButtonProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'className'> {
  variant?: 'filter' | 'dropdown' | 'icon';
  isLoading?: boolean;
  icon?: React.ReactNode;
  children: React.ReactNode;
}

/**
 * To use this component in different places, add the class "group/menu-base-button" to
 * the component in order for this component to recognize hover, focus, etc.
 */
export const MenuBaseButton = ({
  variant = 'dropdown',
  isLoading,
  icon,
  children,
  ...rest
}: MenuBaseButtonProps) => {
  return (
    <div
      {...rest}
      className={clsx(
        'flex items-center py-2 text-sm font-medium text-zinc-800 outline-none -outline-offset-1 hover:text-black group-data-[open]/menu-base-button:text-teal-900 group-data-[focus]/menu-base-button:outline-2 group-data-[focus]/menu-base-button:outline-teal-600',
        variant === 'dropdown' &&
          'gap-x-3 rounded-md border border-sage-500 px-3',
        variant === 'filter' && 'gap-x-1',
      )}
    >
      {(icon || isLoading) && (
        <div className="size-5 shrink-0 text-sage-600">
          {isLoading ? (
            <div role="status">
              <p className="sr-only">Loading...</p>
              <LoadingIcon
                className="size-5 shrink-0 text-teal-900"
                aria-hidden="true"
              />
            </div>
          ) : (
            icon
          )}
        </div>
      )}

      {variant !== 'icon' && (
        <>
          <div className="truncate">{children}</div>

          <SelectorIcon
            className="ml-auto size-5 shrink-0 text-sage-600"
            aria-hidden="true"
          />
        </>
      )}
    </div>
  );
};
