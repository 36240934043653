import { clsx } from 'clsx';
import {
  Link,
  LoaderFunction,
  Outlet,
  redirect,
  resolvePath,
  useMatch,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { PageTitle } from '@mosey/components/page-header/PageTitle';
import { MessageIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { convertDateToUTC, threeMonthPeriod } from '@mosey/utils/dates';
import { isoDate } from '../../utils/format';
import { IFetchApi } from '../../utils/types';
import { apiBatch } from '../../utils/fetchApi';
import { useUser } from '../../hooks/useUser';
import { APP_BASE_URL } from '../../settings/config';
import { InboxMessage } from '../../components';
import { useMailroomData } from './utils/hooks';
import { RegionStatus } from '../../types';
import { isDone } from '../../utils/requirement';
import { ResolvedMailToggle } from './common/ResolvedMailToggle';
import { MailDateRangeFilter } from './common/MailDateRangeFilter';
import { RegionFilter } from './common/RegionFilter';

export const loader: LoaderFunction = async ({ request, params }) => {
  const { searchParams } = new URL(request.url);
  const legacyMailId = searchParams.get('mail_id');

  if (legacyMailId) {
    let redirectUrl = resolvePath(`mail/${legacyMailId}`).pathname;

    searchParams.delete('mail_id');

    if (searchParams.size > 0) {
      redirectUrl += `?${searchParams.toString()}`;
    }

    return redirect(redirectUrl);
  }

  const locationId =
    params.locationId?.toLowerCase() ||
    searchParams.get('region')?.toLowerCase();
  const today = convertDateToUTC(new Date());
  const { startDate: requirementStartDate, endDate: requirementEndDate } =
    threeMonthPeriod(today);
  const startDate = isoDate(requirementStartDate);
  const endDate = isoDate(requirementEndDate);
  const hideResolved = searchParams.get('hide_resolved');
  const mailFilterStartDate = searchParams.get('start_date');
  const mailFilterEndDate = searchParams.get('end_date');
  const mailQuery = new URLSearchParams();

  if (mailFilterStartDate) {
    mailQuery.set('start_date', mailFilterStartDate);
  }

  if (mailFilterEndDate) {
    mailQuery.set('end_date', mailFilterEndDate);
  }

  if (hideResolved !== null) {
    mailQuery.set('hide_resolved', hideResolved);
  }

  let mailQueryString = mailQuery.toString();

  if (mailQueryString) {
    mailQueryString = `?${mailQueryString}`;
  }

  const mailURL = locationId
    ? `/api/legal_entity/locations/${locationId}/mail${mailQueryString}`
    : `/api/legal_entity/mail${mailQueryString}`;

  const calls: Record<string, IFetchApi> = {
    messages: {
      url: mailURL,
      method: 'GET',
    },
    users: {
      url: '/api/users',
      method: 'GET',
    },
  };

  /**
   * We only need to get the requirements if we're filtered to a specific region
   * to show the registered agent transfer banner for that particular location.
   * In the global view showing all locations, we don't have a banner.
   */
  if (locationId) {
    calls.requirements = {
      url: '/api/requirements',
      method: 'POST',
      body: {
        /* eslint-disable camelcase */
        start_date: startDate,
        end_date: endDate,
        region_codes: [locationId],
        /* eslint-enable camelcase */
        tags: ['foreign-qualification'],
      },
    };

    /**
     * We only need to make this call if we're in the global inbox and the user
     * has filtered mail to a location. If we're in the location detail view mail
     * tab, this call is made at top route so no need to fetch again.
     */
    calls.legalEntityRegion = {
      url: `/api/legal_entity/locations/${locationId}`,
      method: 'GET',
    };
  }

  return apiBatch(calls);
};

export const Component = () => {
  const isGlobal = useMatch('/mail/:mailId?');
  const {
    legal_entity: { incorporation_region: incorporationRegion },
  } = useUser();
  const params = useParams();
  const { messages, legalEntityRegion, requirements } = useMailroomData();
  const [searchParams] = useSearchParams();
  const currentRegionCode =
    searchParams.get('region')?.toLowerCase() || params.locationId;
  const isForeignQualified = isGlobal ? false : requirements?.data.some(isDone);
  const isIncorporationState =
    incorporationRegion?.id.toLowerCase() === currentRegionCode;
  const shouldShowRegisteredAgentBanner =
    legalEntityRegion &&
    !legalEntityRegion.registered_agent &&
    legalEntityRegion.status !== RegionStatus.Entering &&
    (isIncorporationState || isForeignQualified);

  return (
    <div className="flex h-full flex-col bg-white">
      {isGlobal && (
        <PageHeader>
          <PageTitle>Inbox</PageTitle>

          <ResolvedMailToggle />
          <RegionFilter />
          <MailDateRangeFilter />
        </PageHeader>
      )}

      {shouldShowRegisteredAgentBanner && (
        <div
          className={clsx(
            'flex items-center bg-teal-700 py-3 text-white',
            isGlobal && 'px-8',
            /**
             * TODO: Update this once Tasks Overview is applied to location
             * detail pages and <PageHeader /> is used there.
             */
            !isGlobal && 'pl-7 pr-6',
          )}
        >
          <div className="flex-1">
            <h2 className="font-medium">Registered Agent Transfer</h2>
            <p className="text-sm">
              You can transfer your existing registered agent to Mosey and read
              your mail here.
            </p>
          </div>

          <Button
            as={Link}
            to={`/automation/registered-agent-transfer/${legalEntityRegion.region.id}?callback_url=${APP_BASE_URL}/locations/usa/${legalEntityRegion.region.id.toLowerCase()}/mail`}
          >
            Start transfer
          </Button>
        </div>
      )}

      {!isGlobal && (
        <header className="flex items-center border-b p-4">
          <h3 className="font-bold">Inbox</h3>

          <div className="ml-auto flex gap-x-4">
            <ResolvedMailToggle />
            <MailDateRangeFilter />
          </div>
        </header>
      )}

      {messages.length === 0 ? (
        <InboxMessage
          icon={<MessageIcon />}
          title="No Messages Yet"
          description="Once you receive mail it will show up here."
        />
      ) : (
        <Outlet />
      )}
    </div>
  );
};
