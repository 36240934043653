import { FunctionComponent, createElement } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { clsx } from 'clsx';
import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { PageTitle } from '@mosey/components/page-header/PageTitle';
import { RefreshIcon, LocationIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { USMap, Location as USMapLocation, Section } from '../components';
import { MenuItem, MenuList } from '../components/navigation/Menu';
import { LegalEntityRegionRef, RegionStatus } from '../types';
import { useUser } from '../hooks/useUser';

const LocationEmpty: FunctionComponent = () => (
  <div className="flex flex-col justify-center bg-white p-6">
    <div className="mb-6 size-10 rounded bg-gray-100 p-2 text-gray-500">
      <LocationIcon />
    </div>
    <div className="mb-6">
      <h3 className="text-xl font-bold">No locations yet</h3>
      <p className="text-gray-500">Get started by adding a new state.</p>
    </div>
  </div>
);

type LocationStatusDescriptionProps = {
  iconComponent: FunctionComponent;
  description: string;
  isDisabled: boolean;
};

const LocationStatusDescription: FunctionComponent<
  LocationStatusDescriptionProps
> = ({ iconComponent, description, isDisabled }) => {
  return (
    <div
      className={clsx(
        'flex h-20 w-60 items-center rounded bg-gray-100 p-3 text-sm font-normal leading-4',
        {
          'text-gray-400': isDisabled,
          'text-zinc-600': !isDisabled,
        },
      )}
    >
      <span
        className={clsx('mr-3 size-12 rounded-full p-3', {
          'bg-gray-200': isDisabled,
          'bg-amber-200 text-amber-700': !isDisabled,
        })}
      >
        {createElement(iconComponent)}
      </span>
      <span className="flex-1">{description}</span>
    </div>
  );
};

type LocationListItemProps = {
  state: LegalEntityRegionRef;
};

const LocationListItem: FunctionComponent<LocationListItemProps> = ({
  state,
}) => {
  const isSettingUp = state.status === RegionStatus.Entering;

  const statusMessage = () => {
    if (state.status === RegionStatus.Entering) {
      return (
        <>
          {state.setup_form_submitted ? (
            <LocationStatusDescription
              iconComponent={RefreshIcon}
              description="State registration is in progress!"
              isDisabled={false}
            />
          ) : (
            <Button
              as={NavLink}
              to={`/locations/usa/${state.region.id}`}
              size="xlarge"
            >
              Start registration
            </Button>
          )}
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Link
      key={state.region.id}
      to={`/locations/usa/${state.region.id.toLowerCase()}`}
    >
      <MenuItem>
        <div className="mr-6 flex flex-col items-start">
          <span className="ml-1 text-ellipsis font-bold">
            {state.region.name}
          </span>
          {isSettingUp && (
            <span className="mt-3 rounded-full bg-teal-700 px-3 py-1 text-xs uppercase text-white">
              setting up
            </span>
          )}
        </div>
        {statusMessage()}
      </MenuItem>
    </Link>
  );
};

export const LocationsView = () => {
  const { legal_entity: legalEntity } = useUser();
  const states = legalEntity.regions.sort((a, b) => {
    if (a.region.name < b.region.name) {
      return -1;
    }
    if (a.region.name > b.region.name) {
      return 1;
    }
    return 0;
  });

  const validStates = states.filter(
    (state) => state.status !== RegionStatus.Exiting,
  );
  const USMapStates = validStates.map((state) => state.region);

  // For now we don't have city level data so this is empty
  const USMapLocations: USMapLocation[] = [];

  return (
    <>
      <PageHeader>
        <PageTitle>Locations</PageTitle>

        <div className="ml-auto">
          <Button as={NavLink} to="/locations/usa/setup" size="large">
            Add new state
          </Button>
        </div>
      </PageHeader>
      <Section className="relative flex h-full flex-col" isFullscreen>
        <div className="flex w-full flex-1 flex-col">
          <div className="flex flex-1">
            <aside className="w-[400px]">
              <MenuList>
                {validStates.length > 0 ? (
                  validStates.map((state) => (
                    <div key={state.region.id}>
                      <LocationListItem state={state} />
                    </div>
                  ))
                ) : (
                  <LocationEmpty />
                )}
              </MenuList>
            </aside>
            <div className="flex-1">
              <USMap
                states={USMapStates}
                locations={USMapLocations}
                className="max-w-3xl"
                fixed
              />
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};
