import { FormProvider, useForm } from 'react-hook-form';
import { BlockAlert } from '@mosey/components/layout/BlockAlert';
import { TextLink } from '@mosey/components/navigation/TextLink';
import { components, TaskStatus } from '@mosey/api-types';
import { Renderer, formSpecToRendererConfig } from '../../../components';
import {
  useTask,
  useTaskActionData,
  useTaskSourceQuestion,
} from '../utils/hooks';
import { SkipButton } from '../common/SkipButton';
import { SubmitButton } from '../common/SubmitButton';
import { TaskActions } from '../common/TaskActions';
import { TaskRadioField } from '../common/forms/RadioField';
import { TaskNumberField } from '../common/forms/NumberField';
import { FormSpec } from '../../../types';
import { NextButton } from '../common/NextButton';
import { Form, useNavigation } from 'react-router-dom';
import { TaskHeader } from '../common/TaskHeader';
import { TaskDateField } from '../common/forms/DateField';
import { TaskContainer } from './TaskContainer';
import { formatQuestionAnswer } from '../utils';

const TaskQuestionAnswered = () => {
  const { form, answer, description } = useTaskSourceQuestion();
  const formattedAnswer = formatQuestionAnswer(answer);

  return (
    <TaskContainer>
      <TaskHeader />

      {description && <p className="max-w-3xl">{description}</p>}

      {form.sections.map((section) => {
        return section.form_fields.map((field) => {
          return (
            <div key={field.name} className="mt-4 space-y-8">
              <p>{field.label}</p>
              <div className="relative w-fit min-w-52 space-y-1 rounded border border-gray-200 px-6 py-4 uppercase text-zinc-700">
                <p className="absolute left-2 top-0 -translate-y-1/2 bg-white px-1 text-xs">
                  Your answer
                </p>
                <p className="text-2xl font-medium leading-[48px]">
                  {formattedAnswer}
                </p>
              </div>
              <p>
                If you need to update this answer, please{' '}
                <TextLink to="mailto:support@mosey.com" target="_blank">
                  contact support
                </TextLink>
                .
              </p>
            </div>
          );
        });
      })}

      <TaskActions>
        <NextButton defaultToHome>Next</NextButton>
      </TaskActions>
    </TaskContainer>
  );
};

interface SingleFieldFormProps {
  formId: string;
  field: components['schemas']['Field'];
  value: string | number | null | undefined;
}

const SingleFieldForm = ({ formId, field, value }: SingleFieldFormProps) => {
  const actionData = useTaskActionData();

  /**
   * TODO: Move these mega components into the main /components
   * directory once the feature stablizes.
   *
   * TODO: Once feature is stable, consider merging into <Renderer />
   */
  let content;
  const props = {
    field,
    errors: actionData?.errors,
  };

  switch (field.component.type) {
    case 'radio':
      content = <TaskRadioField value={value} {...props} />;
      break;

    case 'number':
      content = <TaskNumberField value={value} {...props} />;
      break;

    case 'date':
      content = <TaskDateField value={value} {...props} />;
      break;
  }

  return (
    <Form method="POST" id={formId} replace>
      {content}
    </Form>
  );
};

const TaskQuestionForm = () => {
  const { state } = useNavigation();
  const { id } = useTask();
  const { description, form, answer } = useTaskSourceQuestion();
  const actionData = useTaskActionData();
  const formMethods = useForm();
  const {
    formState: { errors },
  } = formMethods;
  const isSingleField =
    form.sections.length === 1 && form.sections[0].form_fields.length === 1;
  const formId = `task-question-form_${id}`;

  return (
    <TaskContainer>
      <TaskHeader />

      <BlockAlert
        variant="error"
        message={actionData?.errors?.submit}
        scrollIntoView
        show={!!actionData?.errors?.submit && state === 'idle'}
        aria-live="assertive"
      />

      {description && <p className="max-w-3xl">{description}</p>}

      {isSingleField ? (
        <SingleFieldForm
          key={formId}
          formId={formId}
          field={form.sections[0].form_fields[0]}
          value={answer}
        />
      ) : (
        <FormProvider {...formMethods}>
          <Form method="POST" id={formId} key={formId} replace>
            <Renderer
              /**
               * The frontend utilities to process form specs are not using the
               * types generated from the API so we're forcing the type here.
               *
               * This is a temporary solution until the frontend utilities are
               * updated to use the generated types.
               */
              config={formSpecToRendererConfig(form as FormSpec)}
              errors={errors}
            />
          </Form>
        </FormProvider>
      )}

      <TaskActions>
        <SkipButton />
        <SubmitButton form={formId} intent="answer-question">
          Save & continue
        </SubmitButton>
      </TaskActions>
    </TaskContainer>
  );
};

export const TaskQuestion = () => {
  const { status } = useTask();
  const { is_editable: isEditable } = useTaskSourceQuestion();

  if (status === TaskStatus.done && !isEditable) {
    return <TaskQuestionAnswered />;
  }

  return <TaskQuestionForm />;
};
