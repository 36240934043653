import { useLegalEntityRegions } from '../../../hooks/useUser';

export function useHandbookPreviewLink() {
  const regions = useLegalEntityRegions();
  const firstRegion = regions.filter((region) => region.has_employees)[0];

  return firstRegion?.code
    ? `/handbook-preview?region=${firstRegion?.code}`
    : '/handbook-preview';
}
