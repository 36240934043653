import { useEffect, useMemo, useState } from 'react';
import {
  useActionData,
  useAsyncValue,
  useLocation,
  useMatch,
  useMatches,
  useParams,
  useRouteLoaderData,
} from 'react-router-dom';
import {
  components,
  LocationScope,
  paths,
  TaskStatus,
  TaskType,
} from '@mosey/api-types';
import {
  useNextSearchParamsValue,
  usePendingSearchParamsValue,
} from '@mosey/components/hooks/navigation';
import { USStateAbbrev, USStateName } from '@mosey/utils/constants/us-states';
import { ResolverType, TaskRouteParams } from './types';
import { TaskActionData } from '../tasks/types';
import { AutomationTypeEnum } from '../../../types';
import { getNextTask, getTaskDueDate, isTaskOverdue, isTaskTodo } from '.';
import { useUser } from '../../../hooks/useUser';
import {
  clearNewTasksForSession,
  clearTasksCompletedThisSession,
  getNewTasksForSession,
  getTasksCompletedThisSession,
} from './session';
import { PolicyInstance } from '../../handbook/types';

type TasksResponse =
  paths['/api/compliance/tasks']['get']['responses']['200']['content']['application/json'];
type Legislation =
  paths['/api/legislation']['get']['responses']['200']['content']['application/json'];
type Connections =
  paths['/api/connections']['get']['responses']['200']['content']['application/json'];
type LocationSummary =
  paths['/api/legal_entity/locations/summary']['get']['responses']['200']['content']['application/json'];
type RegionConfiguration =
  paths['/api/compliance/tasks/{region_code}/configuration']['get']['responses']['200']['content']['application/json'];
type UsersResponse =
  paths['/api/users']['get']['responses']['200']['content']['application/json'];

type TaskRemediation = components['schemas']['TaskRemediation'];
type TaskRef = components['schemas']['TaskRef'];
type Task = components['schemas']['Task'];

export const useTasks = (exactRouteId?: string) => {
  const { resolverType, locationId, taskId } = useParams<TaskRouteParams>();
  const matches = useMatches();
  let routeId;

  if (exactRouteId) {
    routeId = matches.find(({ id }) => {
      return id === exactRouteId;
    })?.id;
  } else {
    routeId = matches.find(({ id }) => {
      return id.startsWith('resolver-tasks/');
    })?.id;
  }

  const tasksFromApi = useRouteLoaderData(routeId || '') as TaskRef[];
  const tasksCompletedThisSessionJSON = getTasksCompletedThisSession();
  const newTasksThisSessionJSON = getNewTasksForSession(
    locationId,
    resolverType,
  );

  return useMemo(() => {
    if (!tasksFromApi) {
      return [];
    }

    let newTasksThisSessionByParentId: Record<string, string[]> = {};
    let tasksCompletedThisSession: string[] = [];

    if (newTasksThisSessionJSON) {
      try {
        newTasksThisSessionByParentId = JSON.parse(newTasksThisSessionJSON);
      } catch (error) {
        // No new tasks for this session
        clearNewTasksForSession(locationId, resolverType);
      }
    }

    if (tasksCompletedThisSessionJSON) {
      try {
        tasksCompletedThisSession = JSON.parse(tasksCompletedThisSessionJSON);
      } catch (error) {
        // No completed tasks for this session
        clearTasksCompletedThisSession();
      }
    }

    const newTasksThisSessionArr = newTasksThisSessionByParentId
      ? Object.values(newTasksThisSessionByParentId).flat()
      : [];

    return tasksFromApi
      .filter((task) => {
        /**
         * Don't include any tasks that are new this session. They will be added
         * after their parent task below.
         */
        return !newTasksThisSessionArr.includes(task.id);
      })
      .filter((task) => {
        /**
         * If the resolver is assessment or setup, show all tasks
         */
        if (
          resolverType === ResolverType.Review ||
          resolverType === ResolverType.Setup ||
          resolverType === ResolverType.Assessment
        ) {
          return true;
        }

        /**
         * If the resolver is not assessment or setup, only show tasks that are
         * todo, the current task, or tasks that have been completed this session.
         */
        return (
          task.status === TaskStatus.todo ||
          task.id === taskId ||
          tasksCompletedThisSession.includes(task.id)
        );
      })
      .flatMap((task) => {
        const groupedTasks = [task];
        const childTasks = newTasksThisSessionByParentId[task.id];

        if (childTasks) {
          for (let j = 0; j < childTasks.length; j++) {
            const newTaskId = childTasks[j];
            const newTask = tasksFromApi.find((t) => t.id === newTaskId);

            if (newTask) {
              groupedTasks.push(newTask);
            }
          }
        }

        return groupedTasks;
      });
  }, [
    locationId,
    tasksFromApi,
    newTasksThisSessionJSON,
    tasksCompletedThisSessionJSON,
    resolverType,
    taskId,
  ]);
};

const useTaskLoaderData = () => {
  const matches = useMatches();
  const routeId = matches.find(({ id }) => {
    return id.startsWith('task/');
  })?.id;

  if (!routeId) {
    throw new Error(
      'The useTask/useTaskPolicyInstances hooks must be used within a Task route.',
    );
  }

  return useRouteLoaderData(routeId) as {
    task: Task;
    policyInstances: Array<PolicyInstance>;
    users: UsersResponse;
  };
};

export const useTaskPolicyInstances = () => {
  const { policyInstances } = useTaskLoaderData();
  return policyInstances;
};

export const useTask = () => {
  const { task } = useTaskLoaderData();
  return task;
};

export const useTaskSourceQuestion = () => {
  const { source } = useTask();

  return source as components['schemas']['TaskQuestion'];
};

export const useTaskSourceRequirement = () => {
  const { source } = useTask();

  return source as components['schemas']['TaskRequirement'];
};

export const useRemediations = () => {
  const {
    legal_entity: { name: legalEntityName, notice_url: noticeUrl },
  } = useUser();
  const { remediations, location, summary } = useTaskSourceRequirement();
  const isNotices = useIsNoticesAndPostersTask();
  const isPolicyGen = useIsPolicyGenTask();

  if (isNotices) {
    if (!noticeUrl) {
      throw new Error(
        'The notice_url is missing and is required for notice tasks.',
      );
    }

    return [
      {
        id: 'notices',
        title: 'Link to Your Notice Board',
        description:
          'Add the link to your notice board to an internal wiki or document that is readily accessible to all remote employees.',
        order: 0,
        paths: [
          {
            type: 'link',
            value: `${noticeUrl}?region=${location.name}`,
            text: `${location.name} Notice Board – ${legalEntityName}`,
          },
          {
            type: 'link',
            value: noticeUrl,
            text: `Notice Board (All Locations) – ${legalEntityName}`,
          },
        ],
      } as TaskRemediation,
      ...remediations,
    ];
  } else if (isPolicyGen) {
    return [
      {
        id: 'policy-gen',
        title: 'Establish policies',
        description: summary,
        order: 0,
        paths: [
          {
            type: 'policy-generation',
            value: 'policy-generation',
          },
        ],
      } as TaskRemediation,
    ];
  }

  return remediations;
};

export const useTodoTasks = () => {
  const tasks = useTasks();

  return tasks.filter(isTaskTodo);
};

export const useResolverUrl = () => {
  const matches = useMatches();
  return matches.find(({ id }) => id.startsWith('resolver-tasks/'))?.pathname;
};

export const useTaskUrl = () => {
  const matches = useMatches();
  const url = matches.find(({ id }) => id.startsWith('task/'));

  if (!url) {
    throw new Error(
      'The useTaskUrl hook must be used within a Task Resolver Task route.',
    );
  }

  return url.pathname;
};

export const useNextTask = () => {
  const { resolverType, taskId } = useParams();
  const tasks = useTasks();

  const temp = getNextTask(resolverType, tasks, taskId);
  return temp;
};

export const useNextTaskUrl = () => {
  const nextTask = useNextTask();
  const resolverUrl = useResolverUrl();

  if (nextTask && resolverUrl) {
    return `${resolverUrl}/tasks/${nextTask.id}`;
  }

  return null;
};

export const useNextResolverUrl = () => {
  const nextTaskUrl = useNextTaskUrl();
  const resolverUrl = useResolverUrl();

  if (!nextTaskUrl && resolverUrl) {
    return `${resolverUrl}/complete`;
  }

  return nextTaskUrl;
};

export const useResolverLocation = () => {
  const { locationId } = useParams();

  if (locationId) {
    return {
      code: locationId as USStateAbbrev,
      name: USStateName[locationId.toUpperCase() as keyof typeof USStateName],
    };
  }

  return null;
};

export const useTaskLocation = () => {
  const resolverLocation = useResolverLocation();
  const { source } = useTask();

  if (resolverLocation) {
    return resolverLocation;
  } else if (source.type === TaskType.requirement) {
    return {
      code: source.location.id as USStateAbbrev,
      name: source.location.name as USStateName,
    };
  } else if (source.location_scope !== LocationScope.entity) {
    return {
      code: source.locations[0].id as USStateAbbrev,
      name: source.locations[0].name as USStateName,
    };
  }

  return null;
};

export const useTaskActionData = () => {
  return useActionData() as TaskActionData;
};

export const useNewTasks = () => {
  const allTasks = useTasks();
  const taskActionData = useTaskActionData();
  const { state } = useLocation();
  let newTasks: TaskRef[] | undefined;

  if (taskActionData?.newTasks && taskActionData.newTasks.length > 0) {
    newTasks = taskActionData.newTasks;
  } else if (state?.newTasks?.length > 0) {
    newTasks = state.newTasks as TaskRef[];
  }

  if (newTasks) {
    if (allTasks.length > 0) {
      return newTasks.filter((newTask) => {
        return allTasks.find((task) => task.id === newTask.id);
      });
    }

    return newTasks;
  }

  return [];
};

export const useIsNewTask = (id: string) => {
  const newTasks = useNewTasks();
  const newTaskIndex = newTasks.findIndex((newTask) => newTask.id === id);

  return newTaskIndex > -1;
};

export const useIsNoticesAndPostersTask = () => {
  const { source } = useTask();

  return (
    source.type === TaskType.requirement &&
    source.tags.includes(AutomationTypeEnum.NoticeBoard)
  );
};

export const useIsPolicyGenTask = () => {
  const { source } = useTask();

  return (
    source.type === TaskType.requirement &&
    source.tags.includes(AutomationTypeEnum.Handbook)
  );
};

export const useNewTasksTimeToReveal = () => {
  const newTasks = useNewTasks();
  const analyzingTime = 3000;
  const generatingTime = 1000;
  const visibilityDelay = analyzingTime + generatingTime;
  const idealIndividualRevealTime = 1000;
  const maxTotalRevealTimeWithoutDelay = 3000;
  const totalRevealTime = Math.min(
    maxTotalRevealTimeWithoutDelay,
    newTasks.length * idealIndividualRevealTime,
  );

  return { analyzingTime, generatingTime, visibilityDelay, totalRevealTime };
};

export const useNewTaskRevealDelay = (id: string) => {
  const newTasks = useNewTasks();
  const newTaskIndex = newTasks.findIndex((newTask) => newTask.id === id);
  const { visibilityDelay, totalRevealTime } = useNewTasksTimeToReveal();

  return (totalRevealTime / newTasks.length) * newTaskIndex + visibilityDelay;
};

export const useNewTaskVisibility = (id: string) => {
  const { resolverType } = useParams();
  let timeToShow = useNewTaskRevealDelay(id);

  if (resolverType === ResolverType.Assessment) {
    timeToShow = 0;
  }

  const [isVisible, setIsVisible] = useState(timeToShow === 0);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (!isVisible) {
      timeoutId = setTimeout(setIsVisible, timeToShow, true);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isVisible, timeToShow]);

  return isVisible;
};

export const useIsStandaloneRoute = () => {
  const matches = useMatches();
  return !matches.find(({ id }) => id.startsWith('resolver-tasks/'));
};

export const useIsAutomationRoute = () => {
  const taskUrl = useTaskUrl();

  return Boolean(useMatch(`${taskUrl}/automation`));
};

export const useIsOverdue = () => {
  const task = useTask();

  return isTaskOverdue(task);
};

export const useDueDate = () => {
  const task = useTask();

  return getTaskDueDate(task);
};

const _matchPath = (pattern: string): boolean => {
  return !!useMatch(pattern);
};

export const useIsQuestionUrl = () => {
  const baseUrl = '/locations/usa/:locationId/resolver';
  const allowedQuestionUrlPatterns = [
    '/question',
    '/assessment',
    '/question/complete',
    '/assessment/complete',
    '/question/tasks/:taskId',
    '/assessment/tasks/:taskId',
    '/review',
    '/review/complete',
    '/review/tasks/:taskId',
  ];

  const matches = allowedQuestionUrlPatterns.map((pattern) => {
    return _matchPath(`${baseUrl}${pattern}`);
  });

  return matches.some((match) => match === true);
};

interface TasksOverviewData {
  tasks: TasksResponse;
  inProgress: TasksResponse;
  handbookTodos: TasksResponse;
  legislation: Legislation;
  connections?: Connections;
  summary: LocationSummary;
  configuration?: RegionConfiguration;
}

export const useTasksOverviewPendingData = () => {
  const matches = useMatches();
  const routeId = matches.find(({ id }) => {
    return id.startsWith('tasks-overview/');
  })?.id;

  if (!routeId) {
    throw new Error(
      'The useTasksOverviewPendingData hook must be used within a Tasks Overview route.',
    );
  }

  const promise = useRouteLoaderData(routeId) as
    | {
        data: Promise<Response>;
      }
    | undefined;

  if (!promise) {
    throw new Error('Attempted to render Tasks Overview without API data!');
  }

  return promise.data;
};

export const useTasksOverviewData = (): TasksOverviewData => {
  const data = useAsyncValue() as TasksOverviewData;

  if (data === undefined) {
    throw new Error(
      'The useTasksOverviewData hook must be used within a Tasks Overview route.',
    );
  }

  return data;
};

export const useNextTasksOverviewLocationId = () => {
  return useNextSearchParamsValue('location_id');
};

export const useTasksOverviewLocationId = () => {
  const locationId = usePendingSearchParamsValue('location_id');
  const params = useParams();

  return locationId || params.locationId || null;
};

export const useIsLocationDetail = () => {
  return useMatch('/locations/:countryId/:locationId/*');
};

export const useUsers = () => {
  const { users } = useTaskLoaderData();
  return users;
};
