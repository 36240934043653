import { AddIntegrationLink } from '../../../components/AddIntegrationLink';
import { Integrations } from '../../../components/settings/Integrations';
import { useTasksOverviewData } from '../utils/hooks';

export const TasksOverviewIntegrations = () => {
  const { connections } = useTasksOverviewData();

  return (
    <div className="space-y-2">
      <div className="space-y-0.5">
        <h2 className="font-semibold text-zinc-800">Integrations</h2>
        <p className="text-sm text-zinc-600">
          Improve compliance monitoring and receive timely alerts.
        </p>
      </div>

      {connections && connections.length > 0 ? (
        <Integrations connections={connections} />
      ) : (
        <AddIntegrationLink />
      )}
    </div>
  );
};
