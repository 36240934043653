import { useLegalEntityRegions } from '../../../../hooks/useUser';
import { Placeholder } from '@mosey/components/navigation/Placeholder';

export const AddLocationLink = () => {
  const regions = useLegalEntityRegions();

  if (regions.length < 51) {
    return (
      <li data-static-list-item="true">
        <Placeholder to="/locations/usa/setup" size="small" fitToHeight>
          Add location
        </Placeholder>
      </li>
    );
  }

  return null;
};
