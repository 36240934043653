import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { TextLink } from '@mosey/components/navigation/TextLink';
import { Policy } from './Policy';
import { PolicySection as PolicySectionType } from '../../types/policy-handbook';
import { useHandbook } from '../../hooks/useHandbook';
import { PolicyCategory } from './PolicyCategory';

const BUFFER_SPACING = 60;

function findScrollableAncestor(
  element: HTMLElement | null,
): HTMLElement | null {
  if (!element) {
    return null;
  }
  if (element.scrollHeight > element.clientHeight) {
    return element;
  }
  return findScrollableAncestor(element.parentElement);
}

type PolicySectionProps = {
  selectedSection: PolicySectionType;
  nextSection: PolicySectionType;
  getSectionUrl: (sectionId: string, policyId?: string) => string;
};
export const PolicySection = ({
  selectedSection,
  nextSection,
  getSectionUrl,
}: PolicySectionProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { is_acknowledged } = useHandbook();
  const location = useLocation();

  useEffect(() => {
    const scrollElement = findScrollableAncestor(containerRef.current);
    if (!scrollElement) {
      return;
    }
    if (!location.hash) {
      setTimeout(
        () => scrollElement.scrollTo({ top: 0, behavior: 'smooth' }),
        0,
      );
    }
    const element = document.getElementById(location.hash.slice(1));
    if (!element) {
      return;
    }
    setTimeout(() => {
      scrollElement.scrollTo({
        left: 0,
        top: element.offsetTop - element.offsetHeight - BUFFER_SPACING,
        behavior: 'smooth',
      });
    }, 100);
  }, [location.pathname, location.hash]);

  return (
    <div
      ref={containerRef}
      className="flex min-h-[calc(100vh-64px)] flex-1 flex-col overflow-y-auto p-8"
    >
      <div className="flex w-full max-w-screen-md flex-col gap-5">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-semibold tracking-tight">
            {selectedSection.title}
          </h1>
        </div>
        {selectedSection.items.map((item) =>
          item.type === 'policy' ? (
            <Policy key={item.slug} policy={item.policy} />
          ) : (
            <PolicyCategory key={item.slug} category={item} />
          ),
        )}
        {(nextSection?.id || !is_acknowledged) && (
          <div className="ml-auto flex items-center">
            <div className="pr-1.5 text-zinc-700">Next:</div>
            {nextSection?.id && (
              <TextLink to={getSectionUrl(nextSection.id)}>
                {nextSection.title}
              </TextLink>
            )}
            {!nextSection?.id && !is_acknowledged && (
              <TextLink to={`/handbook/acknowledge`}>
                Acknowledge & Sign
              </TextLink>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
