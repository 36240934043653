import { components } from '@mosey/api-types';
import { LoginWithAgencies } from '../components';

export function getLoginDataFromAgencies(
  agencies: components['schemas']['Agency'][],
) {
  const logins: Record<string, LoginWithAgencies> = {};
  agencies.forEach((agency) => {
    agency.agency_accounts.forEach((account) => {
      account.logins.forEach((login) => {
        if (!logins[login.public_id]) {
          logins[login.public_id] = {
            ...login,
            agencies: [],
          };
        }
        if (!logins[login.public_id].agencies.find((a) => a.id === agency.id)) {
          logins[login.public_id].agencies.push(agency);
        }
      });
    });
  });
  return Object.values(logins);
}
