import { components } from '@mosey/api-types';
import { formatEtaAsProse } from '../utils/format';

type RequirementEtaTextProps = {
  eta: components['schemas']['ETARange'];
};

export const RequirementEtaText = ({ eta }: RequirementEtaTextProps) => {
  return (
    <p className="text-xs">
      <span className="font-bold">{formatEtaAsProse(eta)}</span> Processing
      times may vary.
    </p>
  );
};
