import { useSearchParams } from 'react-router-dom';
import { LocationIconFilled } from '@mosey/components/Icons';
import { DropdownMenu, MenuItem } from '@mosey/components/menus/DropdownMenu';
import { useLegalEntityRegions } from '../../../hooks/useUser';

export const RegionFilter = () => {
  const regions = useLegalEntityRegions();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentRegionCode = searchParams.get('region')?.toLowerCase();
  const currentLegalEntityRegion = regions.find(
    ({ region }) => region.id.toLowerCase() === currentRegionCode,
  );

  return (
    <DropdownMenu
      buttonText={currentLegalEntityRegion?.region.name ?? 'All Locations'}
      ariaButtonText="Change mail location filter:"
      ButtonLeftIcon={LocationIconFilled}
    >
      <MenuItem
        key="All Locations"
        as="button"
        aria-label="Filter mail to All Locations"
        LeftIcon={LocationIconFilled}
        aria-pressed={!currentRegionCode}
        selected={!currentRegionCode}
        onClick={() => {
          searchParams.delete('region');
          setSearchParams(searchParams);
        }}
      >
        All Locations
      </MenuItem>
      {regions.map(({ region }) => (
        <MenuItem
          key={region.name}
          as="button"
          aria-label={`Filter mail to ${region.name}`}
          LeftIcon={LocationIconFilled}
          aria-pressed={region.id.toLowerCase() === currentRegionCode}
          selected={region.id.toLowerCase() === currentRegionCode}
          onClick={() => {
            const targetRegionCode = region.id.toLowerCase();

            searchParams.set('region', targetRegionCode);
            setSearchParams(searchParams);
          }}
        >
          {region.name}
        </MenuItem>
      ))}
    </DropdownMenu>
  );
};
