import { PolicyContentRenderer } from '@mosey/components/handbook/PolicyContentRenderer';
import { PolicyItem } from '../types';
import { Button } from '@mosey/components/buttons/Button';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useEffect, useMemo, useRef } from 'react';
import { Link, useFetcher } from 'react-router-dom';
import { getPolicyLocationLabel } from '../utils';
import { PolicyFullDetails } from '@mosey/components/handbook/PolicyFullDetails';
import { getStateSealImage } from '../../../utils/seals';
import { USStateAbbrev } from '@mosey/utils/constants/us-states';
import { Pill } from '@mosey/components/badges/Pill';
import { UpdatedIcon } from '@mosey/components/Icons';
import { PencilAltIcon } from '@heroicons/react/outline';
import { usePolicySectionAttributeValues } from '../hooks/usePolicySectionAttributeValues';
import { fillPolicyContent } from '@mosey/utils/templates/render';

type PolicyDetailSectionProps = {
  item: PolicyItem;
  onAdopted: () => void;
};

export const PolicyDetailSection = ({
  item,
  onAdopted,
}: PolicyDetailSectionProps) => {
  const { policy } = item;
  const { Form, state, data } = useFetcher();
  const hasBeenAdopted = useRef(false);

  useEffect(() => {
    if (data?.success && !hasBeenAdopted.current) {
      hasBeenAdopted.current = true;
      onAdopted();
    }
  }, [onAdopted, data?.success]);

  const attributeValues = usePolicySectionAttributeValues();

  const content = useMemo(
    () =>
      fillPolicyContent(
        policy.content,
        attributeValues,
        policy.policy_scope?.region_id,
      ),
    [attributeValues, policy],
  );

  const isUpdatable = item.status === 'updateable';
  const isAdoptable = item.status === 'adoptable';
  const isUpdated = item.status === 'updated';

  return (
    <Form id={item.slug} className="mb-10" method="POST">
      <input type="hidden" name="policy_id" value={policy.id} />
      <div className="mb-3 flex min-h-12 items-center gap-3 border-b-1 border-gray-200 pb-2">
        {policy.policy_scope?.region && (
          <img
            className="size-10"
            src={getStateSealImage(
              policy.policy_scope.region.id as USStateAbbrev,
            )}
            alt={`State seal of ${policy.policy_scope.region.name}`}
          />
        )}
        <div className="flex flex-1 flex-col">
          <h2 className="font-semibold">{item.title}</h2>
          {policy.policy_scope?.region && (
            <p className="text-sm text-gray-700">
              {`Applies to ${getPolicyLocationLabel(policy)}-based employees`}
            </p>
          )}
        </div>
        {policy.is_custom_policy && (
          <Link
            to={`../${policy.slug}`}
            className="mr-6 flex items-center gap-2 font-semibold text-teal-900"
          >
            <PencilAltIcon className="size-[18px]" />
            Edit
          </Link>
        )}
        {isUpdatable || isAdoptable ? (
          <Button
            variant="secondary"
            type="submit"
            name="intent"
            value="adopt-policy"
            isLoading={state !== 'idle'}
            aria-label={`${isUpdatable ? 'Update' : 'Adopt'} ${policy.title}`}
          >
            {isUpdatable ? 'Update' : 'Adopt'}
          </Button>
        ) : (
          <Pill
            Icon={isUpdated ? UpdatedIcon : CheckCircleIcon}
            variant="success"
          >
            {isUpdated ? 'Updated' : 'Adopted'}
          </Pill>
        )}
      </div>
      <div className="max-w-2xl">
        {content.main.map((piece, index) => (
          <PolicyContentRenderer key={index} content={piece} />
        ))}
        {content.full_details && (
          <div className="mt-3">
            <PolicyFullDetails fullDetails={content.full_details} />
          </div>
        )}
      </div>
    </Form>
  );
};
