import { useEffect } from 'react';
import { Navigate, useFetcher, useSearchParams } from 'react-router-dom';
import { DownloadIcon } from '@heroicons/react/outline';
import { LegalEntityMailStatus, paths } from '@mosey/api-types';
import { Pill } from '@mosey/components/badges/Pill';
import { Button } from '@mosey/components/buttons/Button';
import { RobotIcon } from '@mosey/components/Icons';
import { DropdownMenu, MenuItem } from '@mosey/components/menus/DropdownMenu';
import { MailPreview } from '../../components';
import { useCurrentMail, useMailroomData } from './utils/hooks';
import { nameFromUser } from '../../utils/format';
import { api } from '../../utils/fetchApi';

type MailPatch =
  paths['/api/legal_entity/mail/{mail_public_id}']['patch']['requestBody']['content']['application/json'];

export const MailroomMail = () => {
  const mail = useCurrentMail();
  const fetcher = useFetcher();
  const [searchParams] = useSearchParams();
  const { users, messages } = useMailroomData();
  const operation = (fetcher.json as MailPatch)?.operation;

  useEffect(() => {
    if (mail && !mail.is_read && operation !== 'read_state') {
      fetcher.submit(
        // eslint-disable-next-line camelcase
        { operation: 'read_state', is_read: true },
        { method: 'PATCH', encType: 'application/json' },
      );
    }
  }, [mail, fetcher, operation]);

  if (!mail) {
    let url = `../${messages[0].id}`;

    if (searchParams.size > 0) {
      url += `?${searchParams.toString()}`;
    }

    return <Navigate to={url} replace />;
  }

  const assignedUserName =
    (mail.assigned_user && nameFromUser(mail.assigned_user)) ?? 'Unassigned';

  /**
   * TODO: Eventually move this into the loader since we're downloading the mail
   * twice: once in the iframe and once here.
   */
  const onDownloadClick = async () => {
    const path = new URL(mail.url).pathname;
    const mailTitle = `${mail.sender}-${mail.id}.pdf`;
    const response = await api({
      url: path,
      method: 'GET',
      contentType: '',
    });
    const data = await response.blob();
    const objectURL = URL.createObjectURL(data);
    const alink = document.createElement('a');

    alink.href = objectURL;
    alink.download = mailTitle;
    alink.click();
  };

  return (
    <div
      data-testid="mailroom-mail-preview"
      className="flex grow flex-col border-l"
    >
      <div className="flex items-center justify-between border-b p-4">
        <span className="text-sm font-semibold text-gray-500">Status</span>
        <span className="ml-2 mr-4 inline-block border-r pr-4">
          <Pill
            variant={mail.status === 'resolved' ? 'success' : 'inactive'}
            size="small"
          >
            {mail.status}
          </Pill>
        </span>
        <span className="inline text-sm font-semibold text-gray-500">
          Assigned to
        </span>
        <span className="ml-2">
          <DropdownMenu
            buttonText={assignedUserName}
            ariaButtonText={assignedUserName ? 'Assigned to' : ''}
          >
            {users.map((user) => {
              const userName = nameFromUser(user);
              const isAssigned = user.id === mail.assigned_user?.id;

              return (
                <MenuItem
                  as="button"
                  aria-label={`Assign to ${userName}`}
                  key={user.id}
                  aria-pressed={isAssigned}
                  selected={isAssigned}
                  onClick={() => {
                    fetcher.submit(
                      // eslint-disable-next-line camelcase
                      { operation: 'assign', user_id: user.id },
                      { method: 'PATCH', encType: 'application/json' },
                    );
                  }}
                >
                  {userName}
                </MenuItem>
              );
            })}

            <MenuItem
              as="button"
              aria-label="Unassign mail"
              selected={!mail.assigned_user}
              aria-pressed={!mail.assigned_user}
              onClick={() => {
                fetcher.submit(
                  { operation: 'unassign' },
                  { method: 'PATCH', encType: 'application/json' },
                );
              }}
            >
              Unassigned
            </MenuItem>
          </DropdownMenu>
        </span>

        <div className="ml-auto flex items-center gap-2">
          <Button
            variant="secondary"
            onClick={onDownloadClick}
            isFullWidth
            rightIcon={<DownloadIcon className="ml-2 w-4" />}
          >
            Download
          </Button>

          <Button
            variant={mail.status === 'resolved' ? 'secondary' : 'primary'}
            type="submit"
            isFullWidth
            onClick={() => {
              fetcher.submit(
                {
                  operation: 'status_update',
                  status:
                    mail.status === 'resolved'
                      ? LegalEntityMailStatus.unresolved
                      : LegalEntityMailStatus.resolved,
                },
                { method: 'PATCH', encType: 'application/json' },
              );
            }}
            isLoading={
              fetcher.state !== 'idle' && operation === 'status_update'
            }
          >
            {mail.status === 'resolved' ? 'Unresolve' : 'Mark resolved'}
          </Button>
        </div>
      </div>
      <div className="flex flex-col border-b bg-violet-50 px-6 py-4 text-sm">
        <div className="flex">
          <dl className="grid flex-1 grid-cols-2">
            {[
              { title: 'From', value: mail.summary.sender },
              { title: 'Re', value: mail.summary.title },
              { title: 'To', value: mail.summary.recipient },
              { title: 'Sent', value: mail.summary.sent_date },
            ]
              .filter(({ value }) => !!value)
              .map(({ title, value }) => (
                <div className="flex gap-2" key={title}>
                  <dt className="font-bold">{title}:</dt>
                  <dd className="line-clamp-1" title={value || ''}>
                    {value}
                  </dd>
                </div>
              ))}
          </dl>
          {mail.summary.classification == 'tax_rate_change' && (
            <Pill variant="automation">
              {mail.summary.classification.replaceAll('_', ' ')}
            </Pill>
          )}
        </div>
        <div className="mt-4 rounded bg-violet-200 p-4">
          {mail.summary.explanation}
        </div>
        <div className="mt-3 flex items-center space-x-1 text-xs font-semibold text-violet-500">
          <RobotIcon className="w-4" />
          <div>Mosey AI Generated Summary</div>
          <div className="flex-1 text-right font-normal">
            AI-powered summaries can make mistakes. Check important information.
          </div>
        </div>
      </div>

      <MailPreview url={mail.url} />
    </div>
  );
};
