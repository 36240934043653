import { useUser } from '../../../hooks/useUser';

export function useIsHandbookEnabled() {
  const {
    legal_entity: { is_handbook_enabled: isHandbookEnabled },
  } = useUser();
  return isHandbookEnabled;
}

export function useIsHandbookPresent() {
  const {
    legal_entity: { is_handbook_present: isHandbookPresent },
  } = useUser();
  return isHandbookPresent;
}
