import { useState } from 'react';
import { clsx } from 'clsx';
import { HomeIcon } from '@heroicons/react/solid';
import { components } from '@mosey/api-types';
import { StateSealCropped } from '../state-seals/StateSealCropped';
import {
  LightningBoltListIcon,
  QuestionListIcon,
  StopwatchIcon,
} from '../Icons';

type Region = components['schemas']['Region'];

const DEFAULT_CONTAINER_TAG = 'div';

export type PolymorphicProps<T extends React.ElementType, P = object> = P & {
  as?: T;
} & Omit<React.ComponentPropsWithoutRef<T>, keyof P | 'className' | 'children'>;

export type LocationCardBaseProps = {
  region: Region;
  selected?: boolean;
  status?: 'home' | 'assessment' | 'setup' | 'overdue';
  notification?: 'unread_mail';
};

export type LocationCardProps<T extends React.ElementType> = PolymorphicProps<
  T,
  LocationCardBaseProps
>;

export const LocationCard = <
  T extends React.ElementType = typeof DEFAULT_CONTAINER_TAG,
>({
  as,
  selected = false,
  region,
  status,
  notification,
  ...props
}: LocationCardProps<T>) => {
  const Component = as || DEFAULT_CONTAINER_TAG;
  const [isHovered, setIsHovered] = useState(false);
  let Icon: React.ElementType | null = null;
  let iconAriaLabel: string | undefined;

  switch (status) {
    case 'overdue':
      Icon = StopwatchIcon;
      iconAriaLabel = 'has overdue tasks';
      break;

    case 'assessment':
      Icon = QuestionListIcon;
      iconAriaLabel = 'has assessment tasks';
      break;

    case 'setup':
      Icon = LightningBoltListIcon;
      iconAriaLabel = 'has setup tasks';
      break;

    case 'home':
      Icon = HomeIcon;
      iconAriaLabel = 'Incorporation State';
      break;
  }

  return (
    <div
      onMouseEnter={(event) => {
        setIsHovered(true);

        if (props.onMouseEnter) {
          props.onMouseEnter(event);
        }
      }}
      onMouseLeave={(event) => {
        setIsHovered(false);

        if (props.onMouseLeave) {
          props.onMouseLeave(event);
        }
      }}
    >
      <Component
        {...props}
        className={clsx(
          'flex w-full shrink-0 grow items-center gap-x-2 rounded border py-2 pl-2 pr-3 text-left text-sm font-medium focus-visible:outline-2 focus-visible:outline-teal-800',
          selected
            ? 'border-teal-700 bg-teal-700 text-teal-50'
            : 'border-teal-350 bg-teal-300 text-zinc-800 hover:border-teal-500 hover:bg-teal-400 hover:text-black',
        )}
      >
        <div className="relative size-6 shrink-0">
          <StateSealCropped
            opacity={1}
            tinted={!(selected || isHovered)}
            code={region.id}
          />
          {notification && (
            <div
              className="absolute -right-0.5 top-px size-2 rounded-full border border-white bg-rose-700"
              aria-label={
                notification === 'unread_mail' ? 'Unread mail' : undefined
              }
            />
          )}
        </div>

        <div className="shrink-0 grow text-nowrap">{region.name}</div>

        {Icon && (
          <Icon
            className={clsx(
              'ml-auto shrink-0',
              status === 'home' ? 'size-3' : 'size-4',
              selected
                ? 'text-teal-50'
                : status === 'overdue'
                  ? 'text-rose-900'
                  : 'text-teal-800',
            )}
            aria-label={iconAriaLabel}
          />
        )}
      </Component>
    </div>
  );
};
