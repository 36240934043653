import {
  FunctionComponent,
  Suspense,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useNavigate,
  useParams,
  useLocation,
  Navigate,
  Route,
  Outlet,
  LoaderFunction,
  redirect,
  matchPath,
  json,
  defer,
  useAsyncValue,
  useRouteLoaderData,
  Await,
  Link,
  useMatch,
  useResolvedPath,
} from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { clsx } from 'clsx';
import { components, TaskStatus } from '@mosey/api-types';
import { USStateAbbrev, isUSStateCode } from '@mosey/utils/constants/us-states';
import {
  ActionIcon,
  FingerPrintIcon,
  RecurIcon,
  EmailIcon,
} from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { SentryRoutes as Routes } from '../sentry';
import {
  BatchApiStatusHandler,
  Section,
  SectionHeading,
  Tooltip,
} from '../components';
import { LegalEntityRegion, RegionStatus, Requirement } from '../types';
import { LocationDetailSetupView } from './LocationDetailSetup';
import { LocationDetailSetupViewNew } from './LocationDetailSetup_NEW';
import { LocationDetailRecurringView } from './LocationDetailRecurring';
import { useBatchApi } from '../hooks';
import { FatalError } from './FatalError';
import { getStateSealImage } from '../utils/seals';
import { LocationDetailProvider } from '../contexts/locationDetailContext';
import { useIsQuestionUrl } from './tasks-framework/utils/hooks';
import { api } from '../utils/fetchApi';
import { ResolverType } from './tasks-framework/utils/types';
import { Loading } from './Loading';
import { useUser } from '../hooks/useUser';
import { LocationDetail as LocationDetailNew } from './LocationDetail';
import { isLocationDetailPreview } from './tasks-framework/utils';

type TaskRef = components['schemas']['TaskRef'];

type MenuItemProps = {
  label: string;
  icon: React.ReactNode;
  isSelected: boolean;
  isDisabled: boolean;
  isDisabledMessage?: string;
  linkTo: string;
};

export const MenuItem: FunctionComponent<MenuItemProps> = ({
  label,
  icon,
  isSelected,
  isDisabled,
  isDisabledMessage,
  linkTo,
}) => {
  const navigate = useNavigate();
  return (
    <Tooltip
      name={`tooltip-${label}`}
      label={isDisabled ? isDisabledMessage : null}
    >
      <div
        className={clsx(
          'group relative flex cursor-pointer px-1 py-4 text-lg font-bold',
          {
            "text-rose-700 after:content-[''] after:bg-rose-700 after:w-full after:h-0.5 after:absolute after:left-0 after:bottom-0":
              isSelected,

            'text-gray-500': !isSelected,
            'cursor-default': isDisabled,
          },
        )}
        onClick={() => {
          if (!isDisabled) {
            navigate(linkTo);
          }
        }}
      >
        <div
          className={clsx('mx-auto flex items-center gap-x-2', {
            'hover:text-sage-800': !isSelected && !isDisabled,
            'text-sage-700': !isSelected,
          })}
        >
          <span
            className={clsx('size-5 transition-colors', {
              'text-rose-700 opacity-40': isSelected,
            })}
          >
            {icon}
          </span>
          <span
            className={clsx(
              'inline-flex h-full items-center transition-colors',
            )}
          >
            {label}
          </span>
        </div>
      </div>
    </Tooltip>
  );
};

type LocationDetailProps = {
  legalEntityRegion: LegalEntityRegion;
  requirements: Requirement[];
  triggerRefresh: () => void;
};

export const LocationDetail: FunctionComponent<LocationDetailProps> = ({
  legalEntityRegion,
  requirements,
  triggerRefresh,
}) => {
  const location = useLocation();
  const [navSetupComplete, setNavSetupComplete] = useState<boolean>(
    legalEntityRegion.is_setup_complete,
  );
  const { region } = legalEntityRegion;
  const locationId = region.id.toLowerCase();

  // Determine which tab we are on.
  const { pathname } = useLocation();
  const isLegalEntityStatusMaintaining =
    legalEntityRegion.status === RegionStatus.Maintaining;
  const isSetupTabSelected = pathname.endsWith('setup');
  const isRecurringTabSelected = pathname.endsWith('recurring');
  const isMailTabSelected =
    useMatch(useResolvedPath('mail/*').pathname) !== null;
  const isAccountsSelected = pathname.endsWith('accounts');

  const topElementRef = useRef<HTMLDivElement | null>(null);

  const questionTasksDone = useAsyncValue() as TaskRef[];

  useEffect(() => {
    if (isRecurringTabSelected) {
      triggerRefresh();
    }
  }, [isRecurringTabSelected]);

  const handleSetupComplete = () => {
    setNavSetupComplete(true);
    triggerRefresh();
  };

  return (
    <Section isFullscreen className="flex h-full flex-col">
      <div className="sticky top-0 z-30 flex items-center justify-between border-b bg-white px-6 py-4">
        <div className="flex items-center">
          <img
            src={getStateSealImage(region.id as USStateAbbrev)}
            className="mr-4 size-12"
            alt="State seal"
          />
          <SectionHeading className="p-0" borderless text={`${region.name}`} />
        </div>
        {questionTasksDone.length > 0 && (
          <Button
            variant="secondary"
            as={Link}
            to={`/locations/usa/${region.id.toLowerCase()}/resolver/review`}
            state={{ resolverFinishUrl: location.pathname }}
          >
            Review configuration
          </Button>
        )}
      </div>
      <div
        ref={topElementRef}
        className="flex items-center gap-x-8 border-b bg-sage-200 px-6"
      >
        <MenuItem
          label="Setup"
          isSelected={isSetupTabSelected}
          isDisabled={false}
          icon={<ActionIcon />}
          linkTo={`/locations/usa/${locationId}/setup`}
        />
        <MenuItem
          label="Recurring"
          isSelected={isRecurringTabSelected}
          isDisabled={!isLegalEntityStatusMaintaining}
          isDisabledMessage="Complete setup to track recurring requirements"
          icon={<RecurIcon />}
          linkTo={`/locations/usa/${locationId}/recurring`}
        />
        <MenuItem
          label="Mail"
          isSelected={isMailTabSelected}
          isDisabled={!isLegalEntityStatusMaintaining}
          isDisabledMessage="Complete setup to see your mail settings"
          icon={<EmailIcon />}
          linkTo={`/locations/usa/${locationId}/mail`}
        />
        <MenuItem
          label="Accounts"
          isSelected={isAccountsSelected}
          /* accounts tab is always enabled */
          isDisabled={false}
          icon={<FingerPrintIcon />}
          linkTo={`/locations/usa/${locationId}/accounts`}
        />
      </div>
      <div className="relative h-full flex-1 bg-sage-100">
        <Outlet />
        <Routes>
          <Route
            path="setup"
            element={
              <ErrorBoundary fallback={<FatalError />}>
                {legalEntityRegion.is_setup_complete && navSetupComplete ? (
                  <LocationDetailSetupView
                    legalEntityRegion={legalEntityRegion}
                    requirements={requirements}
                    triggerParentRefresh={triggerRefresh}
                  />
                ) : (
                  <LocationDetailSetupViewNew
                    topElementRef={topElementRef}
                    navSetupComplete={navSetupComplete}
                    legalEntityRegion={legalEntityRegion}
                    requirements={requirements}
                    triggerParentRefresh={triggerRefresh}
                    handleSetupComplete={handleSetupComplete}
                  />
                )}
              </ErrorBoundary>
            }
          />
          <Route
            path="recurring"
            element={
              <ErrorBoundary fallback={<FatalError />}>
                <LocationDetailRecurringView
                  legalEntityRegion={legalEntityRegion}
                />
              </ErrorBoundary>
            }
          />
          <Route path="/" element={<Navigate to="setup" replace />} />
        </Routes>
      </div>
    </Section>
  );
};

export const LocationDetailView: FunctionComponent = () => {
  const { questionTasksDone } = useRouteLoaderData('question-tasks-jm') as {
    questionTasksDone: Promise<TaskRef[]>;
  };
  const { locationId } = useParams<Record<string, string>>();
  const [triggerRefresh, setTriggerRefresh] = useState<boolean>(false);
  const batchResponse = useBatchApi(
    [
      {
        url: `/api/legal_entity/locations/${locationId}`,
        method: 'GET',
      },
      {
        url: `/api/requirements/${locationId}/navigator`,
        method: 'GET',
      },
    ],
    [triggerRefresh],
  );

  const componentPropsFn = ([
    legalEntityRegionResponse,
    requirementsResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ]: any[]): LocationDetailProps => {
    return {
      legalEntityRegion: legalEntityRegionResponse,
      requirements: requirementsResponse.data,
      triggerRefresh: () => setTriggerRefresh((prev) => !prev),
    };
  };

  return (
    <Suspense fallback={<Loading />}>
      <Await resolve={questionTasksDone}>
        <BatchApiStatusHandler
          batchResponse={batchResponse}
          component={LocationDetail}
          componentProps={componentPropsFn}
        />
      </Await>
    </Suspense>
  );
};

export const LocationViewGate = () => {
  const user = useUser();
  const isQuestionUrl = useIsQuestionUrl();

  // TODO: Remove this once Tasks Overview is live on Location Detail
  if (user.role === 'admin' && isLocationDetailPreview()) {
    return <LocationDetailNew />;
  }

  if (isQuestionUrl) {
    return <Outlet />;
  }

  return (
    <LocationDetailProvider>
      <LocationDetailView />
    </LocationDetailProvider>
  );
};

export const loader: LoaderFunction = async ({
  params: { countryId, locationId },
  request,
}) => {
  const { pathname } = new URL(request.url);

  if (
    matchPath(
      'locations/:countryId/:locationId/resolver/:resolverType?/*',
      pathname,
    )
  ) {
    return json({ questionTasksTodo: [], questionTasksDone: [] });
  }

  let todoUrl = '/api/compliance/tasks';
  let doneUrl = '/api/compliance/tasks';
  const searchParams = new URLSearchParams();

  if (countryId && locationId) {
    if (locationId && isUSStateCode(locationId.toUpperCase())) {
      searchParams.set('region_id', locationId);
    } else {
      return redirect(`/home`);
    }
  }

  searchParams.set('task_type', ResolverType.Question);
  searchParams.set('statuses', TaskStatus.todo);

  if (searchParams.size > 0) {
    todoUrl = `${todoUrl}?${searchParams.toString()}`;
  }

  searchParams.set('statuses', TaskStatus.done);
  if (searchParams.size > 0) {
    doneUrl = `${doneUrl}?${searchParams.toString()}`;
  }

  return defer({
    questionTasksTodo: api({ url: todoUrl, method: 'GET' }).then((res) =>
      res.json(),
    ),
    questionTasksDone: api({ url: doneUrl, method: 'GET' }).then((res) =>
      res.json(),
    ),
  });
};
