import { ComponentProps, Suspense, lazy, useEffect, useState } from 'react';

const AnimationInternal = lazy(() =>
  import('./AnimationInternal').then((module) => ({
    default: module.Animation__DONOTUSEDIRECTLY,
  })),
);

type AnimationProps = {
  loadAnimation: () => Promise<{ default: object }>;
  // animationData: object;
  className?: string;
  config?: ComponentProps<typeof AnimationInternal>['config'];
};

/**
 * This component should be used for all Lottie animations. It is set up to
 * exclude the lottie library and animations from the main bundle.
 *
 * The `className` prop should typically be used for sizing. A blank loading element will
 * respect the `className` while lottie and the the animation are loading. When in doubt
 * set `w-full max-w-X aspect-[Y/Z]` to ensure the animation is responsive.
 *
 * Example `loadingAnimation` prop: `() => import('./assets/animations/something.json')`
 */
export const Animation = ({
  loadAnimation,
  className,
  config,
}: AnimationProps) => {
  const [animationData, setAnimationData] = useState<object | null>(null);
  useEffect(() => {
    loadAnimation().then((data) => {
      setAnimationData(data.default);
    });
  });
  return (
    <Suspense fallback={<div className={className} />}>
      <AnimationInternal
        animationData={animationData}
        className={className}
        config={config}
      />
    </Suspense>
  );
};
