import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { PageTitle } from '@mosey/components/page-header/PageTitle';
import * as TasksOverview from './tasks-framework/overview/TasksOverview';

export const Home = () => {
  return (
    <div className="flex h-full flex-col overflow-hidden">
      <PageHeader>
        <PageTitle>Home</PageTitle>
      </PageHeader>

      <TasksOverview.Component />
    </div>
  );
};
