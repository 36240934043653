import { Button } from '@mosey/components/buttons/Button';
import { CheckCircleIcon } from '@mosey/components/Icons';
import { GuidedStepContentProps } from './types';

export const CompleteStep = ({ login, reset }: GuidedStepContentProps) => {
  return (
    <div className="mt-8 flex flex-col items-start gap-8">
      <div className="flex items-center gap-4">
        <CheckCircleIcon className="size-12 text-lime-600" />
        <h5 className="text-2xl font-semibold tracking-tight">
          Login completed
        </h5>
      </div>
      <p className="max-w-xl">
        You can now access your {login.account_name} account.
      </p>
      <Button onClick={reset} type="button" variant="secondary">
        Log in again
      </Button>
    </div>
  );
};
