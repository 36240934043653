import { useSearchParams } from 'react-router-dom';
import { Field, Label } from '@headlessui/react';
import { Toggle } from '@mosey/components/toggles/Toggle';

export const ResolvedMailToggle = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Field className="flex items-center gap-x-2">
      <Toggle
        checked={searchParams.get('hide_resolved') === 'true'}
        onChange={() => {
          const hideResolved = !(searchParams.get('hide_resolved') === 'true');
          searchParams.set('hide_resolved', hideResolved.toString());
          setSearchParams(searchParams);
        }}
      />
      <Label className="text-sm text-zinc-700">Hide resolved</Label>
    </Field>
  );
};
