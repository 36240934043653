import { useSearchParams } from 'react-router-dom';
import { DateRangePicker } from '../../../components/dates/DateRangePicker';

export const MailDateRangeFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <DateRangePicker
      defaultStartDate={searchParams.get('start_date')}
      defaultEndDate={searchParams.get('end_date')}
      ariaButtonTextPrefix="Change mail date filter:"
      defaultButtonText="All Time"
      onRangeChange={(newStartDate, newEndDate) => {
        if (newStartDate) {
          searchParams.set('start_date', newStartDate);
        } else {
          searchParams.delete('start_date');
        }

        if (newEndDate) {
          searchParams.set('end_date', newEndDate);
        } else {
          searchParams.delete('end_date');
        }

        setSearchParams(searchParams);
      }}
    />
  );
};
