import {
  FunctionComponent,
  useState,
  useEffect,
  MouseEvent,
  useRef,
} from 'react';
import { clsx } from 'clsx';
import { CheckIcon, LoadingIcon, SelectIcon } from '@mosey/components/Icons';
import { User } from '../types';
import { nameFromUser } from '../utils/format';

type AssignSelectProps = {
  assignedTo?: User;
  users: User[];
  className?: string;
  alignRight?: boolean;
  isLoading?: boolean;
  // If the userId is null then it is unassigned
  onChange: (userId: string | null) => void;
};

export const AssignSelect: FunctionComponent<AssignSelectProps> = ({
  users,
  className,
  assignedTo,
  alignRight,
  isLoading,
  onChange,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isAssignVisible, setAssignVisible] = useState(false);
  const [assignedToId, setAssignedToId] = useState(assignedTo?.id || null);

  useEffect(() => {
    if (assignedTo?.id !== assignedToId) {
      setAssignedToId(assignedTo?.id || null);
    }
  }, [assignedTo]);

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        isAssignVisible &&
        ref.current &&
        e.target instanceof Node &&
        !ref.current.contains(e.target)
      ) {
        setAssignVisible(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isAssignVisible]);

  const toggleAssignVisibility = (e: MouseEvent) => {
    e.preventDefault();
    setAssignVisible((prev: boolean) => !prev);
  };

  const handleAssignToClick = (e: MouseEvent) => {
    e.preventDefault();
    const id = e.currentTarget.getAttribute('data-id');
    setAssignedToId(id);
    setAssignVisible(false);
    onChange(id);
  };

  const teamMemberNameById = (id: string) => {
    const user = users.find((t: User) => t.id === id);
    if (user) {
      return nameFromUser(user);
    }
    return undefined;
  };

  return (
    <div
      className={clsx(
        'relative inline-block rounded-md border px-3 py-2 text-left',
        className,
      )}
    >
      <div
        className="flex w-auto cursor-pointer items-center text-gray-500 hover:text-rose-700"
        onClick={toggleAssignVisibility}
      >
        {isLoading ? (
          <LoadingIcon className="mr-1 inline size-5 cursor-pointer text-gray-400" />
        ) : (
          <>
            <span className="flex-1 text-sm">
              {assignedToId ? teamMemberNameById(assignedToId) : 'Unassigned'}
            </span>
            <span className="ml-2 size-4">
              <SelectIcon />
            </span>
          </>
        )}
      </div>
      {isAssignVisible && (
        <div
          className={clsx(
            'absolute z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black/5',
            {
              '-right-2': alignRight,
              '-left-4': !alignRight,
            },
          )}
          role="menu"
        >
          <div
            ref={ref}
            className="max-h-64 overflow-y-scroll py-1"
            role="none"
          >
            {users.map((t: User, idx) => (
              <div
                key={`user-${t.id}`}
                tabIndex={idx}
                role="menuitem"
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                data-id={t.id}
                onClick={handleAssignToClick}
              >
                <span className="flex-1">{nameFromUser(t)}</span>
                {t.id === assignedToId && (
                  <span className="rounded-xl bg-rose-700 p-1 text-white">
                    <CheckIcon className="size-3" />
                  </span>
                )}
              </div>
            ))}
            <div
              className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              tabIndex={users.length}
              role="menuitem"
              data-id={null}
              onClick={handleAssignToClick}
            >
              <span className="flex-1">Unassigned</span>
              {!assignedToId && (
                <span className="rounded-xl bg-rose-700 p-1 text-white">
                  <CheckIcon className="size-3" />
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssignSelect;
