import { Tab } from '@headlessui/react';
import { TabLike } from '@mosey/components/layout/TabLike';

interface TaskTabProps {
  Icon: React.ComponentType<
    Pick<React.HTMLAttributes<HTMLElement>, 'aria-hidden' | 'className'>
  >;
  children: React.ReactNode;
}

export const TaskTab = ({ Icon, children }: TaskTabProps) => {
  return (
    <Tab className="group/tab-like mb-[-2px] outline-none">
      {({ selected, focus }) => (
        <TabLike isActive={selected} isFocused={focus} icon={<Icon />}>
          {children}
        </TabLike>
      )}
    </Tab>
  );
};
