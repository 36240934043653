import { CheckCircleIcon } from '@heroicons/react/solid';
import { RadioGroup, Radio, Label } from '@headlessui/react';
import { components } from '@mosey/api-types';
import { FieldError } from './FieldError';
import { TaskQuestionFieldBaseProps } from './types';

export const TaskRadioField = ({
  field: { name, label, description, required, component },
  value,
  errors = {},
}: TaskQuestionFieldBaseProps) => {
  const descriptionId = `description-${name}`;
  const errorMessageId = `form-field-error-message-${name}`;
  const error = errors[name];

  return (
    <RadioGroup
      name={name}
      aria-required={Boolean(required)}
      aria-invalid={Boolean(error)}
      aria-describedby={[errorMessageId, description ? descriptionId : ''].join(
        ' ',
      )}
      defaultValue={value}
    >
      <Label className="mb-6 flex flex-col gap-y-2">
        <div className="max-w-3xl">{label}</div>
        {description && <p id={descriptionId}>{description}</p>}
      </Label>

      <div className="flex gap-x-6">
        {(component.params as components['schemas']['RadioParams']).options.map(
          (option: { name: string; value: unknown }) => (
            <Radio
              key={option.name}
              value={option.value}
              className="flex h-20 max-w-48 grow cursor-pointer items-center gap-x-4 rounded-lg border border-stone-100 bg-white px-6 py-4 text-2xl font-bold text-zinc-800 ring-inset hover:border-stone-200 hover:bg-stone-100 focus:outline-none data-[checked]:border-teal-800 data-[focus]:border-teal-600 data-[checked]:bg-teal-700 data-[checked]:text-teal-50 data-[focus]:ring-2 data-[focus]:ring-teal-600 data-[focus]:ring-offset-0"
            >
              {({ checked }) => (
                <>
                  <div className="shrink-0 grow-0" aria-hidden>
                    {checked ? (
                      <CheckCircleIcon className="size-7 scale-125" />
                    ) : (
                      <div className="size-7 rounded-full border border-stone-200 bg-white" />
                    )}
                  </div>

                  {option.name}
                </>
              )}
            </Radio>
          ),
        )}
      </div>

      <FieldError id={errorMessageId} error={Boolean(error)}>
        {error}
      </FieldError>
    </RadioGroup>
  );
};
