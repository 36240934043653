import { EmployeeAcknowledgementStatus, components } from '@mosey/api-types';
import { useRouteLoaderData, useSearchParams } from 'react-router-dom';
import { aggregateAcknowledgements } from '../utils';
import { FilterOption } from '@mosey/components/menus/Filter';
import { getStatusIconData } from '@mosey/components/layout/StatusIcon';

export type EmployeeAcknowledgementInfo =
  components['schemas']['EmployeeAcknowledgementInfo'];

function getLocations(
  allEmployees: Array<EmployeeAcknowledgementInfo>,
  employees: Array<EmployeeAcknowledgementInfo>,
) {
  const regionData: Record<
    string,
    {
      region: EmployeeAcknowledgementInfo['region'];
      count: number;
    }
  > = {};

  allEmployees.forEach((employee) => {
    if (!regionData[employee.region.id]) {
      regionData[employee.region.id] = {
        region: employee.region,
        count: 0,
      };
    }
  });
  employees.forEach((employee) => regionData[employee.region.id].count++);

  return Object.values(regionData).sort((a, b) =>
    a.region.name.localeCompare(b.region.name),
  );
}

function buildStatusFilter(status: string) {
  return (employee: EmployeeAcknowledgementInfo) =>
    status === 'all' || status === employee.status;
}

function buildLocationFilter(location: string) {
  return (employee: EmployeeAcknowledgementInfo) =>
    location === 'all' || location === employee.region.id;
}

export function useSignatures() {
  const allEmployees = useRouteLoaderData(
    'signatures',
  ) as Array<EmployeeAcknowledgementInfo>;
  allEmployees.sort((a, b) => (a.email > b.email ? 1 : -1));

  // TODO: can remove once backend supports filtering and returns aggregates
  const [searchParams] = useSearchParams();
  const statusFilter = searchParams.get('status') || 'all';
  const locationFilter = searchParams.get('location') || 'all';

  const statusFilteredEmployees = allEmployees.filter(
    buildStatusFilter(statusFilter),
  );
  const locationFilteredEmployees = allEmployees.filter(
    buildLocationFilter(locationFilter),
  );
  const filteredEmployees = [...statusFilteredEmployees].filter(
    buildLocationFilter(locationFilter),
  );

  const locationFilteredAcknowledgements = aggregateAcknowledgements(
    locationFilteredEmployees,
  );

  return {
    allEmployees,
    filteredEmployees,
    filterOptions: {
      status: [
        {
          label: 'Any Status',
          value: 'all',
          count:
            locationFilteredAcknowledgements.signed +
            locationFilteredAcknowledgements.pending +
            locationFilteredAcknowledgements.outdated,
        },
        {
          label: 'Signed',
          value: EmployeeAcknowledgementStatus.signed,
          count: locationFilteredAcknowledgements.signed,
          icon: getStatusIconData('done'),
        },
        {
          label: 'Pending',
          value: EmployeeAcknowledgementStatus.pending,
          count: locationFilteredAcknowledgements.pending,
          icon: getStatusIconData('todo'),
        },
        {
          label: 'Out of date',
          value: EmployeeAcknowledgementStatus.outdated,
          count: locationFilteredAcknowledgements.outdated,
          icon: {
            ...getStatusIconData('update-available'),
            baseColor: 'text-amber-600',
          },
        },
      ] as Array<FilterOption>,
      location: [
        {
          label: 'Any Location',
          value: 'all',
          count: statusFilteredEmployees.length,
        },
        ...getLocations(allEmployees, statusFilteredEmployees).map(
          (location) => ({
            label: location.region.name,
            value: location.region.id,
            count: location.count,
          }),
        ),
      ] as Array<FilterOption>,
    },
  };
}
