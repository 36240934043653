import { Navigate } from 'react-router-dom';
import { useMailroomData } from './utils/hooks';

export const MailroomIndex = () => {
  const { messages } = useMailroomData();
  const mailId = messages[0]?.id;

  if (mailId) {
    return <Navigate to={mailId} />;
  }

  return null;
};
