import {
  MenuButton as HeadlessUIMenuButton,
  MenuButtonProps as HeadlessUIMenuButtonProps,
} from '@headlessui/react';
import { MenuBaseButton, MenuBaseButtonProps } from './common/MenuBaseButton';

type MenuButtonProps = Omit<HeadlessUIMenuButtonProps, 'className'> &
  Pick<MenuBaseButtonProps, 'icon' | 'variant' | 'isLoading'>;

export const MenuButton = ({
  children,
  icon,
  variant,
  isLoading,
  ...rest
}: MenuButtonProps) => {
  return (
    <HeadlessUIMenuButton
      {...rest}
      className="group/menu-base-button outline-none"
      disabled={rest.disabled || isLoading}
    >
      {(bag) => {
        return (
          <MenuBaseButton icon={icon} variant={variant} isLoading={isLoading}>
            {typeof children === 'function' ? children(bag) : children}
          </MenuBaseButton>
        );
      }}
    </HeadlessUIMenuButton>
  );
};
