import { useEffect, useMemo, useState } from 'react';
import { clsx } from 'clsx';
import { Form, useActionData, useNavigation } from 'react-router-dom';
import { components } from '@mosey/api-types';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { usePendingSearchParamsValue } from '@mosey/components/hooks/navigation';
import { TaskCardStatus } from '@mosey/components/layout/types';
import { Field, Label } from '@headlessui/react';
import { Toggle } from '@mosey/components/toggles/Toggle';
import { Button } from '@mosey/components/buttons/Button';
import { BlockAlert } from '@mosey/components/layout/BlockAlert';
import { useTasksOverviewData } from '../utils/hooks';
import {
  getTaskDueDate,
  isTaskAutomated,
  isTaskDone,
  isTaskManaged,
  isTaskOverdue,
  isTaskTodo,
} from '../utils';
import { TasksOverviewZeroState } from './TasksOverviewZeroState';
import { TasksOverviewStatusFilter } from './TasksOverviewStatusFilter';
import { Loading } from '../../Loading';
import { TasksOverviewActionResponse } from './types';

type TaskRef = components['schemas']['TaskRef'];

export const TasksOverviewMainTasks = () => {
  const [isBulkEdit, setIsBulkEdit] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState<
    Record<TaskRef['id'], boolean>
  >({});
  const navigation = useNavigation();
  const actionData = useActionData() as TasksOverviewActionResponse | undefined;
  const status = usePendingSearchParamsValue('status');
  const { tasks } = useTasksOverviewData();

  const filteredTasks = useMemo(() => {
    const result: TaskRef[] = [];

    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];

      if (task.source.type === 'requirement') {
        const isTodo = isTaskTodo(task);
        const isDone = isTaskDone(task);
        const isAutomated = isTaskAutomated(task);
        const isManaged = isTaskManaged(task);
        const isOverdue = isTaskOverdue(task);

        switch (status) {
          case 'done':
            if (isDone && !isAutomated && !isManaged) {
              result.push(task);
            }

            break;

          case 'overdue':
            if (isOverdue) {
              result.push(task);
            }

            break;

          case 'automated':
            if (isAutomated && !isManaged) {
              result.push(task);
            }

            break;

          case 'managed':
            if (isManaged && !isAutomated) {
              result.push(task);
            }

            break;

          case 'todo':
          case null:
          default:
            if (isTodo && !isManaged) {
              result.push(task);
            }

            break;
        }
      }
    }

    return result.sort((a, b) => {
      if (a.source.type === 'requirement' && b.source.type === 'requirement') {
        const aDueDate = a.source.due_date;
        const bDueDate = b.source.due_date;

        if (!aDueDate && !bDueDate) {
          return 0;
        } else if (!aDueDate && bDueDate) {
          return 1;
        } else if (aDueDate && !bDueDate) {
          return -1;
        } else if (aDueDate && bDueDate) {
          const aDate = new Date(aDueDate);
          const bDate = new Date(bDueDate);

          if (aDate < bDate) {
            return -1;
          } else if (aDate > bDate) {
            return 1;
          }
        }

        return 0;
      }

      return 0;
    });
  }, [tasks, status]);

  useEffect(() => {
    if (navigation.state === 'loading') {
      setIsBulkEdit(false);
      setSelectedTasks({});
    }
  }, [navigation.state]);

  const numberOfSelectedTasks =
    Object.values(selectedTasks).filter(Boolean).length;

  const isReloadingTasks =
    navigation.state === 'loading' &&
    navigation.location.pathname === location.pathname;

  return (
    <section>
      <div className="flex items-center">
        <TasksOverviewStatusFilter />

        {(status === null || status === 'todo' || status === 'overdue') &&
          !isReloadingTasks &&
          filteredTasks.length > 1 && (
            <Field className="ml-auto flex items-center gap-x-2">
              <Toggle checked={isBulkEdit} onChange={setIsBulkEdit} />
              <Label className="text-sm text-zinc-700">Bulk edit</Label>
            </Field>
          )}
      </div>

      <div aria-live="polite">
        {isReloadingTasks ? (
          <div className="h-dvh max-h-[calc(100vh-80px-100px-40px)]">
            <Loading />
          </div>
        ) : filteredTasks.length > 0 ? (
          <Form method="POST" className="isolate flex flex-col gap-y-2">
            <BlockAlert
              variant="error"
              message={actionData?.errors?.submit}
              scrollIntoView
              show={!!actionData?.errors?.submit && navigation.state === 'idle'}
              aria-live="assertive"
            />

            {isBulkEdit && (
              <div className="sticky top-2 isolate z-10 flex items-center rounded-sm border border-teal-300 bg-teal-100 px-3 py-2 text-sm text-zinc-700">
                <p>
                  {numberOfSelectedTasks > 0
                    ? `${numberOfSelectedTasks} task${numberOfSelectedTasks > 1 ? 's' : ''} selected`
                    : `Select multiple tasks to edit in bulk`}
                </p>

                <div
                  className={clsx(
                    'ml-auto',
                    numberOfSelectedTasks === 0 && 'invisible',
                  )}
                >
                  <Button
                    disabled={numberOfSelectedTasks === 0}
                    isLoading={navigation.state === 'submitting'}
                    type="submit"
                    name="intent"
                    value="mark-as-done"
                    variant="secondary"
                  >
                    Mark as done
                  </Button>
                </div>
              </div>
            )}

            <ul className="isolate space-y-2">
              {filteredTasks.map((task) => {
                const { id, status, title, source } = task;
                const formattedDueDate = getTaskDueDate(task);
                let calculatedStatus: TaskCardStatus =
                  status === 'deferred' ? 'todo' : status;

                if (isTaskManaged(task)) {
                  calculatedStatus = 'managed';
                } else if (isTaskOverdue(task)) {
                  calculatedStatus = 'overdue';
                } else if (isTaskAutomated(task)) {
                  calculatedStatus = 'automated';
                }

                return (
                  <li key={id} className="flex items-center gap-x-3">
                    {isBulkEdit && (
                      <>
                        <span
                          className="sr-only"
                          id={`task-checkbox-label-${id}`}
                        >
                          {title}
                        </span>
                        <input
                          type="checkbox"
                          aria-labelledby={`task-checkbox-label-${id}`}
                          name={id}
                          value={id}
                          disabled={
                            !isTaskTodo(task) ||
                            navigation.state === 'submitting'
                          }
                          checked={selectedTasks[id] ?? false}
                          onChange={(event) => {
                            setSelectedTasks((prev) => ({
                              ...prev,
                              [id]: event.target.checked,
                            }));
                          }}
                          className="w-4 rounded"
                        />
                      </>
                    )}

                    <div className="min-w-0 max-w-full shrink grow">
                      <TaskSummaryCard
                        status={calculatedStatus}
                        title={title}
                        size="medium"
                        description={
                          source.type === 'requirement'
                            ? source.summary || source.description
                            : undefined
                        }
                        reason={
                          formattedDueDate
                            ? {
                                title: `Due ${formattedDueDate}`,
                                type: 'due',
                              }
                            : undefined
                        }
                        to={
                          source.type === 'question'
                            ? `/resolver/question/tasks/${id}`
                            : `/requirement/${id}`
                        }
                        region={
                          source.type === 'requirement'
                            ? source.location.name
                            : source.locations[0].name
                        }
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </Form>
        ) : (
          <TasksOverviewZeroState />
        )}
      </div>
    </section>
  );
};
