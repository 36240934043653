import { clsx } from 'clsx';
import { NextButton } from '../common/NextButton';
import { useIsLocationDetail, useTasksOverviewData } from '../utils/hooks';
import { AssessmentInterstitial } from '../resolver/AssessmentInterstitial';
import { SetupInterstitial } from '../resolver/SetupInterstitial';
import { isLocationDetailPreview } from '../utils';

interface TasksOverviewInterstitialProps {
  locationId: string;
}

export const TasksOverviewInterstitial = ({
  locationId,
}: TasksOverviewInterstitialProps) => {
  // TODO: Remove this once Tasks Overview is live on Location Detail
  const locationDetailPreview = isLocationDetailPreview();
  const isLocationDetail = useIsLocationDetail();
  const { summary } = useTasksOverviewData();
  const { is_assessment: isAssessment } = summary[locationId.toUpperCase()];

  return (
    <div
      className={clsx(
        'mx-8 mb-8 overflow-hidden rounded-lg border border-teal-350',
        locationDetailPreview && isLocationDetail && 'mt-8',
      )}
    >
      {isAssessment ? (
        <AssessmentInterstitial
          actions={
            <NextButton
              to={`/locations/usa/${locationId}/resolver/assessment?skip=true`}
            />
          }
        />
      ) : (
        <SetupInterstitial
          actions={
            <NextButton
              to={
                // TODO: Remove this once Tasks Overview is live on Location Detail
                locationDetailPreview
                  ? `/locations/usa/${locationId}/resolver/setup?skip=true`
                  : `/locations/usa/${locationId}/setup`
              }
            />
          }
        />
      )}
    </div>
  );
};
