import { ReactNode } from 'react';
import { useScrollIntoView } from '@mosey/components/hooks/index';
import logo from '../../assets/logo.svg';
import { PageNavLink } from '@mosey/components/page-header/PageNavLink';

type RipplingConnectWrapperProps = {
  children: ReactNode;
};

export const RipplingConnectWrapper = ({
  children,
}: RipplingConnectWrapperProps) => {
  const [ref] = useScrollIntoView(true);
  return (
    <div
      className="absolute left-0 top-0 z-50 flex min-h-screen w-screen items-center justify-center bg-white px-4 py-12 sm:px-6 lg:px-8"
      ref={ref}
    >
      <div className="flex min-h-full max-w-2xl flex-1 flex-col items-start px-6 py-12">
        <img className="w-24" src={logo} alt="Mosey" />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Connect Rippling
        </h2>
        <p className="mt-3 text-gray-900">
          Mosey does not have an integration or relationship with Rippling, but
          by taking the following steps, you may export your data from Rippling
          to Mosey so that Mosey can provide its services with respect to that
          data. By doing so, you represent that you have the necessary
          permissions to do so in accordance with Mosey&apos;s terms of service
          and any terms of service agreements you may have in place with
          Rippling or any applicable third party.
        </p>
        <nav className="my-7">
          <ul className="flex items-center gap-10">
            <li>
              <PageNavLink to="/onboarding/rippling/report" variant="small">
                1. Create an Employee Report
              </PageNavLink>
            </li>
            <li>
              <PageNavLink to="/onboarding/rippling/workflow" variant="small">
                2. Create a Workflow Automation
              </PageNavLink>
            </li>
          </ul>
        </nav>
        {children}
      </div>
    </div>
  );
};
