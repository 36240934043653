import { usePolicyList } from './usePolicyList';
import { findNextPolicySection } from '../utils';

export function usePolicySection(id: string) {
  const { sections } = usePolicyList();
  const currentSection =
    sections.find(({ section }) => section.id === id) || sections[0];
  const actionableItems = currentSection.items.filter(
    (item) => item.status === 'adoptable' || item.status === 'updateable',
  );
  const nextActionableSection = findNextPolicySection(
    id,
    sections.filter(({ isActionable }) => isActionable),
  );
  return {
    currentSection,
    nextActionableSection,
    actionableItems,
    hasActionableItems: actionableItems.length > 0,
  };
}
