import { InformationCircleIcon } from '@heroicons/react/outline';
import { BaseSelectField } from '@mosey/components/forms/BaseFormField';
import { OpenNavButton } from '@mosey/handbook/components';
import { ChangeEventHandler, useEffect } from 'react';
import { useRouteLoaderData, useSearchParams } from 'react-router-dom';
import { useLegalEntityRegions } from '../../../hooks/useUser';

export const HandbookPreviewHeader = () => {
  const hasChanges = useRouteLoaderData('handbookHasChanges');
  const regions = useLegalEntityRegions();
  const regionOptions = regions
    .filter((region) => region.has_employees)
    .map((region) => ({
      name: region.name,
      value: region.code,
    }));
  const [searchParams, setSearchParams] = useSearchParams();
  const region = searchParams.get('region') || '';

  const onChange: ChangeEventHandler<HTMLSelectElement> = (ev) => {
    setSearchParams({ region: ev.target.value });
  };

  useEffect(() => {
    if (!region && regionOptions[0]?.value) {
      setSearchParams({ region: regionOptions[0].value });
    }
  }, []);

  return (
    <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-3 border-b border-teal-400 bg-teal-50 px-4 shadow-sm sm:px-6 lg:px-8">
      <OpenNavButton />
      <InformationCircleIcon className="size-6 text-teal-900" />
      <p className="flex-1 text-teal-900">
        {hasChanges ? (
          <>
            <span className="font-semibold">
              This is a preview<span className="lg:hidden">.</span>
            </span>
            <span className="hidden lg:inline">
              {' '}
              &mdash; pending changes have not yet been published.
            </span>
          </>
        ) : (
          <span className="font-semibold">This is a preview.</span>
        )}
      </p>
      {regionOptions.length > 0 && (
        <label className="flex items-center gap-3">
          <span className="hidden text-nowrap text-teal-900 md:inline">
            Select location:
          </span>
          <BaseSelectField
            options={regionOptions}
            onChange={onChange}
            value={region}
          />
        </label>
      )}
    </div>
  );
};
