import { MailroomSidebar } from './MailroomSidebar';
import { MailroomMail } from './MailroomMail';
import { ActionFunction } from 'react-router-dom';
import { MailroomActionResponse } from './types';
import { api } from '../../utils/fetchApi';

export const action: ActionFunction = async ({
  params: { mailId },
  request,
}): Promise<MailroomActionResponse | Response> => {
  const body = await request.json();

  try {
    await api({
      url: `/api/legal_entity/mail/${mailId}`,
      method: request.method,
      body,
    });
  } catch (error) {
    return {
      errors: {
        submit: 'Something went wrong, please try again.',
      },
      resolved: false,
    };
  }

  return {
    errors: {},
    resolved: true,
  };
};

export const Component = () => {
  return (
    <div className="flex grow overflow-hidden">
      <MailroomSidebar />
      <MailroomMail />
    </div>
  );
};
