export function copyLink(title: string, url: string) {
  const anchor = document.createElement('a');
  anchor.setAttribute('href', url);
  anchor.innerText = title;
  copyHtmlToClipboard(anchor.outerHTML, url);
}

export function copyHtmlToClipboard(html: string, fallbackText: string) {
  if (window.ClipboardItem) {
    const htmlBlob = new Blob([html], {
      type: 'text/html',
    });
    const textBlob = new Blob([fallbackText], {
      type: 'text/plain',
    });
    navigator.clipboard.write([
      new ClipboardItem({
        'text/html': htmlBlob,
        'text/plain': textBlob,
      }),
    ]);
  } else {
    navigator.clipboard.writeText(fallbackText);
  }
}
